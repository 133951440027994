export const isHttpUrl = (str: string): boolean => {
  const httpUrlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

  return httpUrlRegex.test(str);
};

export const isBase64DataURI = (str: string): boolean => {
  const base64DataURIRegex = /^data:[^;]+;base64,/i;
  return base64DataURIRegex.test(str);
};

export const isBlobUrl = (url: string): boolean => {
  return url.startsWith("blob:");
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64Data = reader.result as string;

      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const fileToBlob = (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileData = reader.result as ArrayBuffer | string;
        const blob = new Blob([fileData], { type: file.type });
        resolve(blob);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    } else {
      reject();
    }
  });
};

export const convertUrlToBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    method: "GET",
    headers: { "Cache-Control": "no-cache" },
  });
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result as string;

      resolve(base64Data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const blobToBuffer = (blob: Blob): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      // `result` contém os dados como um ArrayBuffer
      if (event.target && event.target.result instanceof ArrayBuffer) {
        // Converte o ArrayBuffer em Buffer usando Uint8Array
        const buffer = Buffer.from(event.target.result);
        resolve(buffer);
      } else {
        reject(new Error("Erro ao ler Blob como ArrayBuffer."));
      }
    };

    reader.onerror = reject;

    // Lê o Blob como ArrayBuffer
    reader.readAsArrayBuffer(blob);
  });
};

export const base64ToBlobUrl = (
  base64String: string,
  contentType: string
): string => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  const blobURL = URL.createObjectURL(blob);
  return blobURL;
};

export const urlToBlobUrl = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    method: "GET",
    headers: { "Cache-Control": "no-cache" },
  });
  const blob = await response.blob();
  const blobURL = URL.createObjectURL(blob);
  return blobURL;
};

export const urlToBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url, {
    method: "GET",
    headers: { "Cache-Control": "no-cache" },
  });
  const blob = await response.blob();
  return blob;
};

export const convertPngToJpeg = async (file: Blob): Promise<Blob | null> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);

      canvas.toBlob(
        (jpegBlob) => {
          URL.revokeObjectURL(url);
          resolve(jpegBlob);
        },
        "image/jpeg",
        0.95
      );
    };

    img.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error("Failed to load image"));
    };

    img.src = url;
  });
};

export const dimensions = (
  base64Image: string
): Promise<{ width: number; height: number; base64: string }> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      const { width, height } = image;
      resolve({
        width,
        height,
        base64: isHttpUrl(base64Image)
          ? `${base64Image}?t=${new Date().getTime()}`
          : isBlobUrl(base64Image)
          ? base64Image
          : `data:image/jpeg;base64,${base64Image}`,
      });
    };
    image.onerror = (event) => {
      reject(event);
    };
    image.src = isHttpUrl(base64Image)
      ? `${base64Image}?t=${new Date().getTime()}`
      : isBlobUrl(base64Image)
      ? base64Image
      : `data:image/jpeg;base64,${base64Image}`;
  });
};

export const dimensionsToPdf = (
  base64Image: string
): Promise<{ width: number; height: number; base64: string }> => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.crossOrigin = "anonymous";
    image.onload = () => {
      const { width, height } = image;
      resolve({
        width,
        height,
        base64:
          isHttpUrl(base64Image) || isBlobUrl(base64Image)
            ? base64Image
            : `data:image/jpeg;base64,${base64Image}`,
      });
    };
    image.onerror = (event) => {
      reject(event);
    };
    image.src =
      isHttpUrl(base64Image) || isBlobUrl(base64Image)
        ? `${base64Image}`
        : `data:image/jpeg;base64,${base64Image}`;
  });
};
