import axios from "axios";

const api = axios.create({
  baseURL: "https://api.datasales.info",
});

export const apiAzure = axios.create({
  baseURL: "https://thumbnail-flyers.azurewebsites.net/flyers",
});

export default api;
