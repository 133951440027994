export default (elements: any[]) => {
  let total_x = 0;
  let total_y = 0;
  let total_w = 0;
  let total_h = 0;

  if (elements && elements.length === 0)
    return {
      total_x,
      total_y,
      total_w,
      total_h,
    };

  total_x = Math.min(...elements.map((item) => item.x));
  total_y = Math.min(...elements.map((item) => item.y));
  total_w = Math.max(...elements.map((item) => item.x + item.width)) - total_x;
  total_h = Math.max(...elements.map((item) => item.y + item.height)) - total_y;

  return {
    total_x,
    total_y,
    total_w,
    total_h,
  };
};
