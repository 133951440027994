import facebookIcon from "../../assets/icons/facebook.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import whatsAppIcon from "../../assets/icons/whatsapp.svg";
import metaIcon from "../../assets/icons/meta.svg";
import smsIcon from "../../assets/icons/sms.svg";
import siteIcon from "../../assets/icons/site.png";
import { SocialNetworksDistributeOptionType } from "../../types/socialNetworks";
import { Pareto, ShippingTypeDistributionOption } from "../../types/whatsapp";
import { SMSShippingTypeDistributionOptions } from "../../types/sms";
import * as yup from "yup";

export const TABS_CHANNELS = [
  {
    id: 0,
    type: "SOCIAL_NETWORKS",
    title: "Facebook e Instagram",
    subTitle: "Publique no perfil das suas redes sociais.",
    checked: false,
    icons: [facebookIcon, instagramIcon],
  },
  {
    id: 1,
    type: "WHATSAPP",
    title: "WhatsApp",
    subTitle: "Envie ofertas para seus contatos do whatsapp.",
    checked: false,
    icons: [whatsAppIcon],
  },
  {
    id: 2,
    type: "FACEBOOK_ADS",
    title: "Impulsionar",
    subTitle: "Alcance novos clientes com Tráfego Pago.",
    checked: false,
    icons: [metaIcon],
  },
  {
    id: 3,
    type: "SMS",
    title: "SMS",
    subTitle: "Envie mensagens de SMS para seus contatos.",
    checked: false,
    icons: [smsIcon],
  },
  {
    id: 4,
    type: "SITE",
    title: "Site",
    subTitle: "Publique no seu site para os seus clientes.",
    checked: false,
    icons: [siteIcon],
  },
];

export const SOCIAL_NETWORK_DISTRIBUTION_OPTIONS: SocialNetworksDistributeOptionType[] =
  [
    {
      label: "Facebook Feed",
      icon: facebookIcon,
      value: "FACEBOOK",
    },
    {
      label: "Instagram Feed",
      icon: instagramIcon,
      value: "INSTAGRAM_FEED",
    },
    {
      label: "Instagram Reels",
      icon: instagramIcon,
      value: "INSTAGRAM_REELS",
    },
    {
      label: "Instagram Story",
      icon: instagramIcon,
      value: "INSTAGRAM_STORY",
    },
  ];

export const WHATSAPP_SHIPPING_TYPES_OPTIONS: ShippingTypeDistributionOption[] =
  [
    {
      name: "CONTACTS",
      value: "contacts",
      label: "Meus contatos",
    },
    {
      name: "ONLY_GROUPS",
      value: "onlyGroups",
      label: "Meus grupos",
    },
    {
      name: "CONTACTS_GROUPS",
      value: "contacts_groups",
      label: "Todos os contatos e grupos",
    },
    {
      name: "ONLY_IN_COPY",
      value: "onlyInCopy",
      label: "Apenas números em cópia",
    },
    {
      name: "AUDIENCE",
      value: "audience",
      label: "Audiência ",
    },
  ];

export const PARETO: Pareto[] = [
  { id: "00/100", label: "100%" },
  { id: "10/90", label: "90%" },
  { id: "20/80", label: "80%" },
  { id: "30/70", label: "70%" },
  { id: "40/60", label: "60%" },
  { id: "50/50", label: "50%" },
  { id: "60/40", label: "40%" },
  { id: "70/30", label: "30%" },
  { id: "80/20", label: "20%" },
  { id: "90/10", label: "10%" },
  { id: "100/00", label: "0%" },
].reverse();

export const HOST_TRACKER = [
  {
    host: "v-ja.co/a/",
    label: "v-ja.co",
  },
];

export const CELULARES = [
  { value: "celular1", label: "Celular 1" },
  { value: "celular2", label: "Celular 2" },
  { value: "celular3", label: "Celular 3" },
  { value: "celular4", label: "Celular 4" },
];

export const CLICK_TRACKER_SMS = " {{clickTracker}}";

export const SMS_SHIPPING_TYPES_OPTIONS: SMSShippingTypeDistributionOptions[] =
  [
    {
      cod: "all",
      label: "Todos",
    },
    {
      cod: "ca",
      label: "Contatos ativos",
    },
    {
      cod: "ci",
      label: "Contatos inativos",
    },
    {
      cod: "cop",
      label: "Somente número em cópia",
    },
  ];

export const OBJECTIVE_PUBLISH_FACEBOOK_ADS = [
  {
    title: "Reconhecimento (Aumentar o reconhecimento da empresa).",
    value: "OUTCOME_AWARENESS", //REACH
    description: `Esse objetivo ajudará você a alcançar o maior número de pessoas com maior probabilidade de lembrar do seu anúncio. Se a sua empresa é nova ou mudou o nome recentemente, o reconhecimento pode ajudar a familiarizar os clientes em potencial com o negócio.`,
    optimization_goal: [
      {
        title: "Mais visibilidade", //"Maximize o alcance dos anúncios",
        value: "REACH",
        description:
          "Vamos mostrar seu anúncio para o maior número de pessoas. É recomendado para quem deseja que as pessoas conheçam ou se lembrem da sua marca.",
        isVideo: true,
        isCarroucel: true,
        isImage: true,
        disabled: false,
      },
      {
        title: "Maximize o número de impressões",
        value: "IMPRESSIONS",
        description:
          "Tentaremos mostrar seus anúncios para as pessoas o maior número de vezes possível.",
        isVideo: true,
        isCarroucel: true,
        isImage: true,
        disabled: true,
      },
      {
        title: "Maximize a incrementalidade na lembrança do anúncio",
        value: "AD_RECALL_LIFT",
        description:
          "Tentaremos mostrar seus anúncios para as pessoas com maior probabilidade de se lembrar deles.",
        isVideo: true,
        isCarroucel: true,
        isImage: true,
        disabled: true,
      },
    ],
  },
  {
    title: "Engajamento (Aumentar o engajamento com a sua página ou anúncio).",
    value: "OUTCOME_ENGAGEMENT", //ENGAJAMENTO
    description: `Encontre as pessoas com mais chances de interagir
      com sua empresa online ou realizar as ações
      desejadas no seu anúncio ou Página.`,
    optimization_goal: [
      {
        title: "Maximizar o número de curtidas na Página",
        value: "PAGE_LIKES",
        description:
          "Incentive as pessoas a se engajar com sua Página do Facebook",
        isVideo: true,
        isImage: true,
        isCarroucel: false,
        disabled: true,
      },
      {
        title: "Curtidas e comentários", //"Maximizar o engajamento com a publicação",
        value: "POST_ENGAGEMENT",
        description: `Vamos mostrar seu anúncio para perfis que tem maior chance de curtir, comentar e compartilhar.`,
        isVideo: true,
        isImage: true,
        isCarroucel: false,
        disabled: false,
      },
      {
        title: "Maximize as visualizações ThruPlay",
        value: "THRUPLAY",
        description: `Tentaremos mostrar seus anúncios em vídeo para as pessoas que assistirão ao vídeo 
          inteiro quando ele tiver menos de 15 segundos. Para vídeos mais longos, tentaremos 
          mostrar para as pessoas com maior probabilidade de assistir a pelo menos 15 segundos.`,
        isVideo: true,
        isImage: false,
        isCarroucel: false,
        disabled: false,
      },
      {
        title: "Maximize as visualizações contínuas de 2 segundos do vídeo",
        value: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS",
        description: `Tentaremos mostrar seus anúncios em vídeo para as pessoas com maior probabilidade
          de assistir a 2 segundos contínuos ou mais. A maioria das visualizações contínuas de 2 segundos
          do vídeo terá pelo menos 50% dos pixels de vídeo na tela.`,
        isVideo: true,
        isImage: false,
        isCarroucel: false,
        disabled: false,
      },
    ],
  },
  {
    title:
      "Tráfego (Direcione as pessoas para um destino, como um site ou perfil do Instagram).",
    value: "OUTCOME_TRAFFIC", //TRAFEGO
    description: `Aumente o tráfego para um destino como sua Página do Instagram ou site.`,
    optimization_goal: [
      {
        title: "Cliques no anúncio",
        value: "LINK_CLICKS",
        description:
          "Vamos mostrar seu anúncio para pessoas que desejam clicar no link ”Saiba Mais”",
        isVideo: true,
        isImage: true,
        isCarroucel: true,
        disabled: false,
      },
      {
        title: "Perfil do Instagram",
        value: "VISIT_INSTAGRAM_PROFILE",
        description: `Direcione tráfego para seu perfil do Instagram.`,
        isVideo: true,
        isImage: true,
        isCarroucel: true,
        disabled: true,
      },
    ],
  },
];

export const ESTIMATED_REACH_FACEBOOK_ADS = {
  1000: "3.500 a 7.000",
  2000: "6.000 a 10.500",
  3000: "10.000 a 15.000",
  4000: "14.500 a 19.000",
  5000: "19.500 a 25.000",
};

export const AGE_PUBLISH_FACEBOOK_ADS_OPTIONS = Array.from(new Array(53)).map(
  (_, index) => index + 18
);

// YUP VALIDATION SCHEMAS ---------------------------------------------------------
export const SOCIAL_NETWORKS_VALIDATION_SCHEMA = yup.object({
  profile: yup.object().required("Selecione uma página."),
  platforms: yup
    .array(yup.object())
    .min(1, "Selecione um canal de distribuição"),
  title: yup.string(),
  post: yup.string(),
});

export const WHATSAPP_VALIDATION_SCHEMA = yup.object().shape({
  selectedInstance: yup
    .object()
    .nullable()
    .required("Selecione uma das instâncias listadas"),
  selectedShippingType: yup
    .object({
      label: yup.string(),
      name: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Selecione uma das opções de envio disponíveis"),
  scheduleDate: yup.date().required("Informe uma data de distribuição"),
  numbersInCopy: yup
    .string()
    .when("selectedShippingType", {
      is: (shippingType: yup.AnyObject) => {
        return shippingType ? shippingType.name === "ONLY_IN_COPY" : false;
      },
      then: (schema: yup.StringSchema) => {
        return schema.required(
          "Você precisa digitar os números para qual você deseja fazer os envios em cópia"
        );
      },
    })
    .nullable(),
  groupsToSend: yup
    .array()
    .of(yup.object())
    .when("selectedShippingType", {
      is: (shippingType: yup.AnyObject) => {
        return shippingType ? shippingType.name === "ONLY_GROUPS" : false;
      },
      then: (schema) =>
        schema.required(
          "Para fazer disparos em grupos de whatsapp, você precisa selecionar os grupos para fazer envios"
        ),
    }),
  agreeWhatsappTerms: yup
    .boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
  // agreeLGPD: yup
  //   .boolean()
  //   .required("The terms and conditions must be accepted.")
  //   .oneOf([true], "The terms and conditions must be accepted."),
  post: yup.string().nullable(),
  auditStatus: yup
    .number()
    .min(0, "A instância está offline.")
    .required("A instância está offline."),
});

export const FACEBOOK_ADS_VALIDATION_SCHEMA = yup.object({
  objective: yup.string().required(),
  optimization_goal: yup.string().required(),
  location: yup
    .array(
      yup
        .object({
          lat: yup
            .number()
            .required("Adicione uma localidade para a distribuição."),
          lng: yup
            .number()
            .required("Adicione uma localidade para a distribuição."),
          radius: yup.number().nullable(),
        })
        .required("Adicione uma localidade para a distribuição.")
    )
    .min(1, "Adicione uma localidade para a distribuição.")
    .required("Adicione uma localidade para a distribuição."),
  clientFacebookPage: yup
    .object({
      page_id: yup.string().nullable(),
    })
    .nullable(),
  link: yup
    .string()
    .url("Link deve ser uma URL válida")
    .when(["clientFacebookPage", "optimization_goal"], {
      is(facebookPage: yup.AnyObject) {
        return facebookPage?.page_id ? !!facebookPage?.page_id : false;
      },
      then(schema: yup.StringSchema) {
        return schema.when("optimization_goal", {
          is: (optimization: string) => {
            return optimization ? optimization === "LINK_CLICKS" : false;
          },
          then(schema: yup.StringSchema) {
            return schema.required("Link deve ser uma URL válida");
          },
          otherwise(schema) {
            return schema.nullable();
          },
        });
      },
      otherwise(schema) {
        return schema.required("Link deve ser uma URL válida");
      },
    }),
});

export const SMS_VALIDATION_SCHEMA = yup.object().shape({
  selectedInstance: yup
    .object({
      id: yup.number(),
    })
    .nullable()
    .required("Selecione uma das instâncias listadas"),
  selectedContactType: yup
    .object({
      cod: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .required("Selecione uma das opções de tipo de contato disponíveis"),
  numbersInCopy: yup
    .string()
    .when("selectedContactType", {
      is: (contactType: SMSShippingTypeDistributionOptions) => {
        return contactType ? contactType.cod === "cop" : false;
      },
      then: (schema: yup.StringSchema) => {
        return schema.required(
          "Você precisa digitar os números para qual você deseja fazer os envios em cópia"
        );
      },
    })
    .nullable(),
});

export const SITE_VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().nullable().required("É necessário um título"),
  description: yup.string().nullable(),
  url: yup.string().nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
});
