import * as AWS from "aws-sdk";
import {
  API_VERSION,
  BUCKET_NAME,
  BUCKET_REGION,
  IDENTITY_POOL_ID,
} from "../utils/configAws";
import { createContext, useCallback, useEffect, useMemo } from "react";
import { Readable } from "stream";

type AwsProviderProps = {
  children: React.ReactNode;
};

export type AwsUploadS3 = {
  isDuplicate?: boolean;
  folderName: string;
  subFolderName?: string;
  file: Buffer | Uint8Array | Blob | string | Readable;
  name?: string;
  distinctName?: boolean;
  bucketName?: string;
  type?: string;
  extension?: string;
  isContacts?: boolean;
};

export type AwsContextData = {
  uploadS3: (media: AwsUploadS3) => any;
};

export const AwsContext = createContext<AwsContextData>({} as AwsContextData);

export function AwsProvider({ children }: AwsProviderProps) {
  const credentials = useMemo(() => {
    return new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IDENTITY_POOL_ID,
    });
  }, []);

  const s3: AWS.S3 = useMemo(() => {
    return new AWS.S3({
      apiVersion: API_VERSION,
      params: { Bucket: BUCKET_NAME },
      credentials,
    });
  }, [credentials]);

  useEffect(() => {
    AWS.config.update({
      region: BUCKET_REGION,
      credentials,
    });
  }, [credentials]);

  const uploadS3 = useCallback(
    async (media: AwsUploadS3): Promise<string> => {
      try {
        const timestamp = new Date().getTime();
        const fileName = `${media.distinctName ? timestamp : media.name}`;
        const folderFilesKey = media.isContacts
          ? `${media.subFolderName}/${encodeURIComponent(media.folderName)}/`
          : `${media.folderName ? `${media.folderName}/` : ""}${
              media.subFolderName ? `${media.subFolderName}` : ""
            }`;
        const fileKey = `${folderFilesKey}${encodeURIComponent(fileName)}.${
          media.extension
        }`;

        const params: AWS.S3.PutObjectRequest = {
          Bucket: media.bucketName || BUCKET_NAME,
          Key: fileKey,
          ContentType: media.type,
          Body: media.file,
          ACL: "public-read",
        };

        await s3.putObject(params).promise();
        const Location = `https://s3.amazonaws.com/${params.Bucket}/${fileKey}`;

        return Promise.resolve(Location);
      } catch (err) {
        console.error("DS ERROR => ", err);
        return Promise.reject("Error upload S3");
      }
    },
    [s3]
  );

  const awsContextData = useMemo(() => {
    return { uploadS3 };
  }, [uploadS3]);

  return (
    <AwsContext.Provider value={awsContextData}>{children}</AwsContext.Provider>
  );
}
