import { ResponseCreateCampaign } from "../types/distribution";
import stage from "../utils/stage";
import api from "./api";

export const postCampaignInstagramCarousel = async (
  data: any
): Promise<any> => {
  const response = await api.post(
    `/social-networks-v2-${stage}/campaing/instagram/carousel`,
    { ...data, VERSION: "V4" }
  );
  return response.data?.content;
};

export const postCampaignFacebook = async (data: any): Promise<any> => {
  const response = await api.post(
    `/social-networks-v2-${stage}/campaing/facebook`,
    { ...data, VERSION: "V4" }
  );
  return response.data?.content;
};

export const postCampaignInstagram = async (data: any): Promise<any> => {
  const response = await api.post(
    `/social-networks-v2-${stage}/campaing/instagram`,
    { ...data, VERSION: "V4" }
  );
  return response.data?.content;
};

export const postCampaignWhatsAppContacts = async (
  data: any
): Promise<ResponseCreateCampaign> => {
  const response = await api.post(`/whatsapp-v2-${stage}/campaign/create`, {
    ...data,
    VERSION: "V4",
  });
  return response.data?.content;
};

export const postCampaignWhatsAppGroups = async (
  data: any
): Promise<ResponseCreateCampaign> => {
  const response = await api.post(
    `/whatsapp-v2-${stage}/campaign/create-group`,
    { ...data, VERSION: "V4" }
  );
  return response.data?.content;
};

export const postCampaignSMS = async (
  data: any
): Promise<ResponseCreateCampaign> => {
  const response = await api.post(`/sms-v2-${stage}/store`, {
    ...data,
    VERSION: "V4",
  });
  return response.data?.content;
};

export const postCampaignSite = async (
  data: any
): Promise<ResponseCreateCampaign> => {
  const response = await api.post(`/sites-${stage}/campaign`, data);
  return response.data?.content;
};

export const postCampaignFacebookAds = async (
  data: any
): Promise<ResponseCreateCampaign> => {
  const response = await api.post(`/ads-manager-${stage}/ads`, {
    ...data,
    version: "V4",
  });
  return response.data?.content;
};

export const postCampaignFacebookAdsV2 = async (
  data: any
): Promise<ResponseCreateCampaign> => {
  const response = await api.post(
    `/ads-manager-${stage}/facebook/v2/ads/create`,
    {
      ...data,
    }
  );
  return response.data?.content;
};

export const findAutoMessage = async (
  backgroundId?: number
): Promise<{ message: string }> => {
  const params = { background_id: backgroundId };
  const response = await api.post(
    "https://2jjyvz29v0.execute-api.us-east-1.amazonaws.com/prd/auto-message",
    params
  );
  return response.data;
};
