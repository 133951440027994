import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { RouterProvider } from "react-router-dom";
import { router as RootRouter } from "./RootRoutes";
import { router as AuthRoutes } from "./AuthRoutes";
import { router as WhitelabelRouter } from "./WhitelabelRoutes";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader";

export function Routes() {
  const { user, loading } = useAuth();

  const isWhitelabel =
    localStorage.getItem("@datasales:isWhitelabel") === "true";

  if (loading) {
    return (
      <Stack
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {!isWhitelabel ? (
          <Loader />
        ) : (
          <LinearProgress sx={{ width: "30%" }} color="primary" />
        )}
      </Stack>
    );
  }

  if (isWhitelabel) {
    return <RouterProvider router={WhitelabelRouter} />;
  } else if (user) {
    return <RouterProvider router={RootRouter} />;
  } else {
    return <RouterProvider router={AuthRoutes} />;
  }
}
