import loadable from "@loadable/component";
import { createBrowserRouter, Navigate } from "react-router-dom";

const SignIn = loadable(() => import("../pages/SignIn"));
const AccountRecover = loadable(() => import("../pages/AccountRecover"));
const SignAsProfessionalArt = loadable(
  () => import("../pages/SignAsProfessionalArt")
);
const SignAsToken = loadable(() => import("../pages/SignAsToken"));
const SignUp = loadable(() => import("../pages/SignUp"));
const SignAsWhitelabel = loadable(() => import("../pages/SignAsWhitelabel"));
const SignAsEditArt = loadable(() => import("../pages/SignAsEditArt"));
const SignAsRedirect = loadable(() => import("../pages/SignAsRedirect"));
const Error = loadable(() => import("../pages/Error"));

export const router = createBrowserRouter([
  {
    id: "hidden-menu-initial",
    path: "/",
    element: <Navigate to="/login" replace />,
    errorElement: <Error />,
  },
  {
    id: "hidden-menu-initial-login",
    path: "/studio",
    element: <Navigate to="/login" replace />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    errorElement: <Error />,
    element: <SignIn />,
  },
  {
    path: "/signup",
    errorElement: <Error />,
    element: <SignUp />,
  },
  {
    path: "/sign-as-user/:token",
    errorElement: <Error />,
    element: <SignAsToken />,
  },
  {
    path: "/sign-as-whitelabel/:token",
    element: <SignAsWhitelabel />,
  },
  {
    path: "/sign-as-whitelabel/:token/:formatId",
    element: <SignAsWhitelabel />,
  },
  {
    path: "/sign-as-whitelabel/:token/:formatId/:products",
    element: <SignAsWhitelabel />,
  },
  {
    path: "/sign-as-professional/:token/:art",
    element: <SignAsProfessionalArt />,
  },
  {
    path: "/sign-as-redirect/:token/:redirect",
    element: <SignAsRedirect />,
  },
  {
    path: "/sign-as-redirect/:token/:redirect/:tabloideId",
    element: <SignAsRedirect />,
  },
  {
    path: "/sign-as-edit/:token/:imageId/:offerId",
    element: <SignAsEditArt />,
  },
  {
    path: "/sign-as-edit/:token/:imageId/:offerId/:products",
    element: <SignAsEditArt />,
  },
  {
    path: "/account-recover",
    errorElement: <Error />,
    element: <AccountRecover />,
  },
  {
    path: "*",
    errorElement: <Error />,
    element: <SignIn />,
  },
]);
