// db.ts
import Dexie, { Table } from "dexie";
import { Editions, Creates } from "../types/dexie";

export class MySubClassedDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  Editions!: Table<Editions>;
  Creates!: Table<Creates>;

  constructor() {
    super("Arts");
    this.version(3).stores({
      Creates: "++id, art, polotno",
      Editions: "++id, art", // Primary key and indexed props
      Tabs: "++id, tabs",
    });
  }
}

export const DexieDB = new MySubClassedDexie();
