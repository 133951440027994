import React, {
  createContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useAuth } from "../hooks/useAuth";
import { DexieDB } from "../services/dexie";
import { Art } from "../types/projects";
import { Colors, Products } from "../types/studio";

type StudioProviderProps = {
  children: React.ReactNode;
};

export type ArtState = {
  id?: number;
  isFavorite?: boolean | undefined;
  isFree?: any;
  elements?: any;
  idDexie?: string;
  editProduct?: boolean;
  typeSelected?: any;
  isCustomArt: boolean;
  logoName?: string;
  layoutSelected: any;
  artName?: string;
  tagURLPrecoUnico: any;
  tagURLDePor: any;
  tagURLVarejAtac: any;
  tagURLNormalClub: any;
  tagURLNormalCartao: any;
  tagURLPackUnit: any;
  folderId?: number;
  type: string;
  segmentId?: number;
  occasionId?: number;
  productQuantity?: number;
  tags?: string;
  products?: Products[];
  formatId?: number;
  folderName?: string;
  tagId: number;
  templateQuantity: number;
  templateId: number;
  templateName?: string;
  backgroundURL: string;
  backgroundId: number;
  designURL: string;
  artsEdit?: Art[];
  designCustomTagURL?: string;
  designFooterURL?: string;
  tagURL: string;
  address?: string;
  cellphone?: string;
  description?: string;
  logoURL?: string;
  storeId?: number;
  footer?: any;
  customTag?: any;
  colors?: { colorPrice: Colors; colorText: Colors };
  hasCustomTag?: boolean;
  isVideo: boolean;
  videoComposition?: string;
  videoTemplateId?: number;
  isEditArt?: boolean;
  isEditModel?: boolean;
  isMultipleEdit?: boolean;
  imageURL?: string;
  changeLayoutAllPages: boolean;
  height?: number;
  width?: number;
  textUpperCase?: boolean;
  removeAllTags?: boolean;
  productFontFamily?: string;
  tagFontFamily?: string;
  footerFontFamily?: string;
  fontCase?: string;
  isLayoutAnimated?: boolean;
};

export type ProfessionalArtState = {
  folderId?: number;
  type: string;
  productQuantity?: number;
  products?: Products[];
  formatId?: number;
  folderName?: string;
  templateId: number;
  designURL: string;
  address?: string;
  cellphone?: string;
  description?: string;
  logoURL?: string;
  storeId?: number;
  backgroundId?: number;
  templateName?: string;
  auxiliar_texts?: { [properties: string]: any };
  auxiliar_texts_colors?: { [properties: string]: any };
  price?: number;
};

type ChangeArtProps = { [properties: string]: any };

export type StudioContextData = {
  loadingPolotno: boolean;
  art: ArtState;
  professionalArt: ProfessionalArtState;
  handleChangeArt(data: ChangeArtProps): void;
  handleChangeProfessionalArt(data: ChangeArtProps): void;
  handleLoadingPolotno(data: boolean): void;
  resetArt(): void;
  resetProfessionalArt(): void;
};

export const StudioContext = createContext<StudioContextData>(
  {} as StudioContextData
);

export function StudioProvider({ children }: StudioProviderProps) {
  const { user } = useAuth();
  const [loadingPolotno, setLoadingPolotno] = useState<boolean>(false);
  const [art, setArt] = useState<ArtState>({
    productQuantity: 1,
    isEditArt: false,
    isEditModel: false,
    isMultipleEdit: false,
    changeLayoutAllPages: true,
  } as ArtState);
  const [professionalArt, setProfessionalArt] = useState<ProfessionalArtState>({
    price: 0,
  } as ProfessionalArtState);

  useLayoutEffect(() => {
    setArt((prevState) => {
      return {
        ...prevState,
        fontCase: user?.studioFontStyle,
        segmentId: user?.segmentStudioId,
      };
    });
  }, [user?.studioFontStyle, user?.segmentStudioId]);

  const handleLoadingPolotno = (data: boolean) => {
    setLoadingPolotno(data);
  };

  const handleDexie = async (prevState: ArtState, data: ChangeArtProps) => {
    const editArt = data.isEditArt || prevState.isEditArt;
    const editModel = data.isEditModel || prevState.isEditModel;
    const model = editArt || editModel ? DexieDB.Editions : DexieDB.Creates;

    if (prevState?.idDexie) {
      const item = await model.get(prevState?.idDexie);
      if (item) {
        await model.update(item, {
          art: { ...prevState, ...data },
        });
      }
    } else {
      await model.add({
        id: prevState?.idDexie || data.idDexie,
        art: { ...data } as ArtState,
      });
    }
  };

  const handleChangeArt = (data: ChangeArtProps) => {
    setArt((prevState) => {
      handleDexie(prevState, data);
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const handleChangeProfessionalArt = (data: ChangeArtProps) => {
    setProfessionalArt((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const resetArt = () => {
    setArt({
      productQuantity: 1,
      isEditArt: false,
      isEditModel: false,
      isMultipleEdit: false,
      changeLayoutAllPages: true,
      fontCase: user.studioFontStyle,
      segmentId: user.segmentStudioId,
    } as ArtState);
  };

  const resetProfessionalArt = () => {
    setProfessionalArt({ price: 0 } as ProfessionalArtState);
  };

  const StudioContextData = useMemo(() => {
    return {
      loadingPolotno,
      art,
      professionalArt,
      handleChangeProfessionalArt,
      handleChangeArt,
      resetArt,
      resetProfessionalArt,
      handleLoadingPolotno,
    };
  }, [
    loadingPolotno,
    art,
    professionalArt,
    handleChangeArt,
    handleChangeProfessionalArt,
    resetArt,
    resetProfessionalArt,
    handleLoadingPolotno,
  ]);

  return (
    <StudioContext.Provider value={StudioContextData}>
      {children}
    </StudioContext.Provider>
  );
}
