import { createTheme, } from "@mui/material/styles";

export const configureDesignSystem = createTheme({
  palette: {
    primary: {
      main: "#0C20B5",
    },
    secondary: {
      main: "#B0B0B0",
    },
    error: { main: "#B02F2F" },
  },

  typography: {
    fontFamily: "Nunito",
    allVariants: {
      textTransform: "none",
      letterSpacing: ".2px",
      lineHeight: "20px",
      color: "#343E75",
    },
  },
  shape: { borderRadius: 8 },
  spacing: 8,
  components: {
    MuiTableCell: {
      defaultProps: { sx: { border: 0, color: "#7285AE", fontWeight: 600 } },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover:hover": {
            backgroundColor: "#E4E7EB !important",
          },
        },
      },
      defaultProps: {
        sx: {
          border: 0,
          "td:first-child": {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          },
          "td:last-child": {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          },
        },
      },
    },
    MuiTextField: { defaultProps: { size: "small" } },
    MuiAutocomplete: { defaultProps: { size: "small" } },
    MuiIconButton: { defaultProps: { size: "small" } },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
        icon: <i className="fi fi-rr-square" />,
        checkedIcon: <i className="fi fi-sr-checkbox" />,
        indeterminateIcon: <i className="fi fi-sr-square-minus" />,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "small",
        sx: { borderRadius: "2000px", padding: "4px 16px" },
      },
      styleOverrides: {
        colorInherit: {
          backgroundColor: "#F2F3F5",
          borderColor: "#E4E7EB",
          color: "#343E75",
          border: "1px solid #CFCFCF",
        },
      },
    },
  },
});
