const capitalizeFirstLetterOfEachWord = (str: string) => {
  // Normaliza para formatação NFC, útil para manipulação Unicode
  const normalizedStr = str.normalize("NFC");

  // Divide a string em palavras
  const words = normalizedStr.split(" ");

  // Capitaliza a primeira letra de cada palavra
  const capitalizedWords = words.map((word) => {
    return (
      word.charAt(0).toLocaleUpperCase() + word.slice(1).toLocaleLowerCase()
    );
  });

  // Junta as palavras novamente em uma string
  return capitalizedWords.join(" ");
};

export const adjustCaseFontReturnString = (
  type: string,
  text: string
): string => {
  if (!Boolean(type)) {
    return text;
  } else {
    switch (type.toLowerCase()) {
      case "upper":
        return String(text).toUpperCase();
      case "lower":
        return String(text).toLowerCase();
      case "title":
        return capitalizeFirstLetterOfEachWord(String(text));
      default:
        return text;
    }
  }
};

export default (type: string, text: string) => {
  switch (type.toLowerCase()) {
    case "upper":
      return String(text).toUpperCase();
    case "lower":
      return String(text).toLowerCase();
    case "title":
      return capitalizeFirstLetterOfEachWord(String(text));
  }
};
