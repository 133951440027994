const keywords = [
  "unidade",
  "un",
  "litro",
  "l",
  "kg",
  "ml",
  "kilo",
  "quilo",
  "gr",
  "grama",
  "bandeja",
  "fardo",
  "caixa",
];

const unitSwitch = (value: string) => {
  switch (value?.toLowerCase()?.replace(" ", "")) {
    case "unidade":
      return "Un";
    case "un":
      return "Un";
    case "litro":
      return "L";
    case "l":
      return "L";
    case "ml":
      return "ML";
    case "kg":
      return "KG";
    case "kilo":
      return "KG";
    case "quilo":
      return "KG";
    case "gr":
      return "Gr";
    case "grama":
      return "Gr";
    case "bandeja":
      return "BDJ";
    case "fardo":
      return "FA";
    case "caixa":
      return "CX";
    default:
      return " ";
  }
};

export const units = (value: string) => {
  if (Number(value.charAt(0)) >= 0) {
    return unitSwitch(value.replace(/[0-9,]/g, "").trim());
  }

  return unitSwitch(value.trim());
};

export const unitReplace = (value: string) => {
  const hasKeywordValue = keywords.some((keyword) =>
    value.toLowerCase().includes(keyword)
  );

  if (value.length === 2 && hasKeywordValue) {
    const tempValue = ` ${value} `;
    return tempValue
      ?.replace(/unidade/gi, "")
      ?.replace(/ un /gi, " ")
      ?.replace(/litro/gi, "")
      ?.replace(/ l /gi, " ")
      ?.replace(/kg/gi, " ")
      ?.replace(/ ml /gi, " ")
      ?.replace(/kilo/gi, "")
      ?.replace(/quilo/gi, "")
      ?.replace(/ gr /gi, " ")
      ?.replace(/grama/gi, "");
  }

  return value
    ?.replace(/unidade/gi, "")
    ?.replace(/ un /gi, " ")
    ?.replace(/litro/gi, "")
    ?.replace(/ l /gi, " ")
    ?.replace(/kg/gi, "")
    ?.replace(/ ml /gi, " ")
    ?.replace(/kilo/gi, "")
    ?.replace(/quilo/gi, "")
    ?.replace(/bandeja/gi, " ")
    ?.replace(/ bdj /gi, " ")
    ?.replace(/ gr /gi, " ")
    ?.replace(/fardo/gi, " ")
    ?.replace(/caixa/gi, " ")
    ?.replace(/grama/gi, "");
};

export const findUnits = (value: string) => {
  let units = value.match(
    /unidade| un |litro| l |kg| ml |kilo|quilo|bandeja| bdj | gr |fardo|caixa|grama/gi
  );

  return units ? units[0] : "";
};
