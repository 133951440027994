import loadable from "@loadable/component";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { isLocalhost } from "../utils/stage";

const Layout = loadable(() => import("../layout"));
const Subscription = loadable(() => import("../pages/Subscription"));
const CancelSubscription = loadable(
  () => import("../pages/CancelSubscription")
);
const Payment = loadable(() => import("../pages/Payment"));
const PaymentExpired = loadable(() => import("../pages/PaymentExpired"));
const Influencers = loadable(() => import("../pages/Influencers"));
const SignAsToken = loadable(() => import("../pages/SignAsToken"));
const Projects = loadable(() => import("../pages/Projects"));
const ViewProject = loadable(() => import("../pages/Projects/View"));
const StudioWhitelabel = loadable(() => import("../pages/StudioWhitelabel"));
const StudioWhitelabelCreate = loadable(
  () => import("../pages/StudioWhitelabel/Create")
);
const StudioSimplify = loadable(() => import("../pages/StudioSimplify"));
const StudioSimplifyArt = loadable(
  () => import("../pages/StudioSimplify/components/Art")
);
const StudioSimplifyProducts = loadable(
  () => import("../pages/StudioSimplify/components/Products")
);
const StudioSimplifyDescription = loadable(
  () => import("../pages/StudioSimplify/components/Description")
);
const StudioSimplifyColors = loadable(
  () => import("../pages/StudioSimplify/components/Colors")
);
const StudioSimplifyTags = loadable(
  () => import("../pages/StudioSimplify/components/Tags")
);
const StudioSimplifyFooter = loadable(
  () => import("../pages/StudioSimplify/components/Footer")
);
const StudioLastMobile = loadable(
  () => import("../pages/StudioSimplify/components/LastMobile")
);
const Studio = loadable(() => import("../pages/Studio"));
const StudioCreate = loadable(() => import("../pages/Studio/Create"));
const StudioArt = loadable(() => import("../pages/Studio/components/Art"));
const StudioProducts = loadable(
  () => import("../pages/Studio/components/Products")
);
const StudioProductsEdit = loadable(
  () => import("../pages/Studio/components/Products/Edit")
);
const StudioLayout = loadable(
  () => import("../pages/Studio/components/Layout")
);
const StudioTags = loadable(() => import("../pages/Studio/components/Tags"));
const StudioTagsProducts = loadable(
  () => import("../pages/Studio/components/Tags/Products")
);

const StudioAparence = loadable(
  () => import("../pages/Studio/components/Aparence")
);
const StudioFooter = loadable(
  () => import("../pages/Studio/components/Footer")
);
const StudioStore = loadable(() => import("../pages/Studio/components/Store"));
const StudioElements = loadable(
  () => import("../pages/Studio/components/Elements")
);
const StudioElementsDs = loadable(
  () => import("../pages/Studio/components/ElementsDs")
);
const StudioTexts = loadable(() => import("../pages/Studio/components/Texts"));
const StudioLayers = loadable(
  () => import("../pages/Studio/components/Layers")
);
const StudioPhotos = loadable(
  () => import("../pages/Studio/components/Photos")
);
const StudioUpload = loadable(
  () => import("../pages/Studio/components/Upload")
);
const StudioResize = loadable(
  () => import("../pages/Studio/components/Resize")
);
const StudioProperties = loadable(
  () => import("../pages/Studio/components/Properties")
);
const StudioCanva = loadable(() => import("../pages/Studio/Canva"));
// const StudioChat = loadable(() => import("../pages/StudioChat"));
const Professional = loadable(() => import("../pages/Professional"));
const ProfessionalCreate = loadable(
  () => import("../pages/Professional/Create")
);
const ProfessionalCreateStore = loadable(
  () => import("../pages/Professional/Create/Store")
);
const SignAsProfessionalArt = loadable(
  () => import("../pages/SignAsProfessionalArt")
);
const SignAsWhitelabel = loadable(() => import("../pages/SignAsWhitelabel"));
const SignAsEditArt = loadable(() => import("../pages/SignAsEditArt"));
const SignAsRedirect = loadable(() => import("../pages/SignAsRedirect"));
const StudioEdit = loadable(() => import("../pages/Studio/Edit"));
const ModelEdit = loadable(() => import("../pages/Studio/Edit/Model"));
const Overview = loadable(() => import("../pages/Overview"));
const Campaigns = loadable(() => import("../pages/Campaigns"));
const ViewCampaign = loadable(() => import("../pages/Campaigns/View"));
const Account = loadable(() => import("../pages/Account"));
const WhatsApp = loadable(() => import("../pages/WhatsApp"));
const ViewWhatsApp = loadable(() => import("../pages/WhatsApp/View"));
const SocialNetworks = loadable(() => import("../pages/SocialNetworks"));
const Radio = loadable(() => import("../pages/Radio"));
const Site = loadable(() => import("../pages/Site"));
const SiteEdit = loadable(() => import("../pages/Site/Edit"));
const SiteCreate = loadable(() => import("../pages/Site/Create"));
const SiteColors = loadable(() => import("../pages/Site/components/Colors"));
const SiteDescription = loadable(
  () => import("../pages/Site/components/Description")
);
const SiteSocial = loadable(() => import("../pages/Site/components/Social"));
const SiteFonts = loadable(() => import("../pages/Site/components/Fonts"));
const SiteLinks = loadable(() => import("../pages/Site/components/Links"));
const SiteOffers = loadable(() => import("../pages/Site/components/Offers"));
const SiteAddress = loadable(() => import("../pages/Site/components/Address"));
const ViewRadio = loadable(() => import("../pages/Radio/View"));
const Learning = loadable(() => import("../pages/Learning"));
const Training = loadable(() => import("../pages/Training"));
const Calls = loadable(() => import("../pages/Calls"));
const Financial = loadable(() => import("../pages/Financial"));
const NotFound = loadable(() => import("../pages/NotFound"));
const Distribution = loadable(() => import("../pages/Distribution"));
const OnBoarding = loadable(() => import("../pages/OnBoarding"));
const SendWhatsApp = loadable(() => import("../pages/SendWhatsApp"));
const SegmentSelect = loadable(() => import("../pages/SegmentSelect"));
const ProfileBusiness = loadable(() => import("../pages/ProfileBusiness"));
const SocialPlanning = loadable(() => import("../pages/SocialPlanning"));
const SocialPlanningCreate = loadable(
  () => import("../pages/SocialPlanning/Create")
);
const SocialPlanningEdit = loadable(
  () => import("../pages/SocialPlanning/Edit")
);
const Error = loadable(() => import("../pages/Error"));

const smartLocal = localStorage.getItem("@datasales:smart") === "true";
const martinsLocal = localStorage.getItem("@datasales:martins") === "true";

export const router = createBrowserRouter([
  {
    id: "hidden-menu-initial-login",
    path: "/login",
    element: <Navigate to="/studio" replace />,
    errorElement: <Error />,
  },
  {
    id: "hidden-menu-initial-signup",
    path: "/signup",
    element: <Navigate to="/studio" replace />,
    errorElement: <Error />,
  },
  {
    id: "hidden-menu-initial",
    path: "/",
    element: <Navigate to="/studio" replace />,
    errorElement: <Error />,
  },
  {
    id: "hidden-menu-home",
    path: "/home",
    element: <Navigate to="/studio" replace />,
    errorElement: <Error />,
  },
  {
    id: "hidden-sign-token",
    path: "/sign-as-user/:token",
    errorElement: <Error />,
    element: <SignAsToken />,
  },
  {
    id: "hidden-sign-whitelabel",
    path: "/sign-as-whitelabel/:token",
    element: <SignAsWhitelabel />,
  },
  {
    id: "hidden-sign-as-whitelabel",
    path: "/sign-as-whitelabel/:token/:formatId",
    element: <SignAsWhitelabel />,
  },
  {
    id: "hidden-sign-as-whitelabel-2",
    path: "/sign-as-whitelabel/:token/:formatId/:products",
    element: <SignAsWhitelabel />,
  },
  {
    id: "hidden-sign-as-professional-2",
    path: "/sign-as-professional/:token/:art",
    element: <SignAsProfessionalArt />,
  },
  {
    id: "hidden-sign-as-redirect-2",
    path: "/sign-as-redirect/:token/:redirect",
    element: <SignAsRedirect />,
  },
  {
    id: "hidden-sign-as-redirect-33",
    path: "/sign-as-redirect/:token/:redirect/:tabloideId",
    element: <SignAsRedirect />,
  },
  {
    id: "hidden-sign-as-edit-2",
    path: "/sign-as-edit/:token/:imageId/:offerId",
    element: <SignAsEditArt />,
  },
  {
    id: "hidden-sign-as-edit",
    path: "/sign-as-edit/:token/:imageId/:offerId/:products",
    element: <SignAsEditArt />,
  },
  {
    id: `${
      (smartLocal || martinsLocal) && "-4-add-divider"
    }login-mobile-sideCreate`,
    path: "/studio",
    errorElement: <Error />,
    element: (
      <Layout>
        <Studio />
      </Layout>
    ),
  },
  {
    id: `"send-4-add-divider"}login-mobile-sideCreate`,
    path: "/projects",
    errorElement: <Error />,
    element: (
      <Layout>
        <Projects />
      </Layout>
    ),
  },
  {
    path: "/social-planning",
    id: `${
      isLocalhost ? '"send-4-add-divider"}' : "hidden-menu-"
    }social-planning-sideCreate`,
    errorElement: <Error />,
    element: (
      <Layout>
        <SocialPlanning />
      </Layout>
    ),
  },
  {
    path: "/social-planning/create",
    id: "social-planning-create",
    errorElement: <Error />,
    element: (
      <Layout>
        <SocialPlanningCreate />
      </Layout>
    ),
  },
  {
    path: "/social-planning/edit",
    id: "social-planning-edit",
    errorElement: <Error />,
    element: (
      <Layout>
        <SocialPlanningEdit />
      </Layout>
    ),
  },
  {
    id: `${
      (smartLocal || martinsLocal) && "hidden-"
    }"send-4-add-divider"}login-sideCreate`,
    path: "/send",
    errorElement: <Error />,
    element: (
      <Layout>
        <SendWhatsApp />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-view-projects",
    path: "/projects/:id",
    errorElement: <Error />,
    element: (
      <Layout>
        <ViewProject />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-view-estudio",
    path: "/estudio/:id",
    element: <Navigate to="/projects" replace />,
    errorElement: <Error />,
  },
  {
    id: "hidden-menu-studio-whitelabel",
    path: "/studio/whitelabel",
    errorElement: <Error />,
    element: (
      <Layout>
        <StudioWhitelabel />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-studio-create-whitelabel",
    path: "/studio/whitelabel/create",
    errorElement: <Error />,
    element: (
      <Layout>
        <StudioWhitelabelCreate />
      </Layout>
    ),
  },
  //   StudioSimplifyArt
  // StudioSimplifyProducts
  {
    id: "hidden-menu-studio-simplify",
    path: "/studio/simplify/:key",
    errorElement: <Error />,
    element: (
      <Layout>
        <StudioSimplify />
      </Layout>
    ),

    children: [
      {
        path: "products",
        element: <StudioSimplifyProducts />,
        id: "studio-simplify-1",
      },
      {
        path: "backgrounds",
        element: <StudioSimplifyArt />,
        id: "studio-simplify-2",
      },
      {
        path: "description",
        element: <StudioSimplifyDescription />,
        id: "studio-simplify-3",
      },
      {
        path: "colors",
        element: <StudioSimplifyColors />,
        id: "studio-simplify-4",
      },
      {
        path: "tags",
        element: <StudioSimplifyTags />,
        id: "studio-simplify-5",
      },
      {
        path: "footer",
        element: <StudioSimplifyFooter />,
        id: "studio-simplify-6",
      },
      {
        path: "lastMobile",
        element: <StudioLastMobile />,
        id: "studio-simplify-99",
      },
    ],
  },
  {
    id: "hidden-menu-studio-create",
    path: "/studio/create/:key",
    errorElement: <Error />,
    element: (
      <Layout>
        <StudioCreate />
      </Layout>
    ),
    children: [
      {
        path: "backgrounds",
        element: <StudioArt />,
        id: "studio-video-ready-1-simpl",
      },
      {
        path: "products",
        element: <StudioProducts />,
        id: "studio-video-ready-2-simpl",
        children: [
          {
            path: "edit",
            element: <StudioProductsEdit />,
            id: "edit-product-01",
          },
        ],
      },
      { path: "layout", element: <StudioLayout />, id: "studio-video-2-simpl" },
      {
        path: "tags",
        element: <StudioTags />,
        id: "studio-video-4-simpl",
        children: [
          {
            path: "products",
            element: <StudioTagsProducts />,
            id: "tags-products-01",
          },
        ],
      },
      {
        path: "aparence",
        element: <StudioAparence />,
        id: "studio-video-5-simpl",
      },
      {
        path: "footer",
        element: <StudioFooter />,
        id: "studio-video-6-footer-simpl",
      },
      { path: "layers", element: <StudioLayers /> },
      {
        path: "store",
        element: <StudioStore />,
        id: "studio-video-ready-3-simpl",
      },
      {
        path: "elements",
        element: <StudioElements />,
        id: "studio-ready-1",
      },
      {
        path: "icons",
        element: <StudioElementsDs />,
        id: "studio-ready-2",
      },
      { path: "texts", element: <StudioTexts />, id: "studio-ready-3" },
      { path: "photos", element: <StudioPhotos />, id: "studio-ready-4" },
      { path: "upload", element: <StudioUpload />, id: "studio-ready-5" },
      {
        path: "resize",
        element: <StudioResize />,
        id: "studio-ready-19",
      },
    ],
  },
  {
    id: "hidden-menu-studio-edit",
    path: "/studio/edit/:key/:id",
    errorElement: <Error />,
    element: (
      <Layout>
        <StudioEdit />
      </Layout>
    ),
    children: [
      {
        path: "backgrounds",
        element: <StudioArt />,
        id: "studio-video-ready-7",
      },
      {
        path: "products",
        element: <StudioProducts />,
        id: "studio-video-ready-8",
        children: [
          {
            path: "edit",
            element: <StudioProductsEdit />,
            id: "edit-product-02",
          },
        ],
      },
      { path: "layout", element: <StudioLayout />, id: "studio-video-edit-88" },
      {
        path: "tags",
        element: <StudioTags />,
        id: "studio-video-9",
        children: [
          {
            path: "products",
            element: <StudioTagsProducts />,
            id: "tags-products-02",
          },
        ],
      },
      { path: "aparence", element: <StudioAparence />, id: "studio-ready-29" },
      {
        path: "footer",
        element: <StudioFooter />,
        id: "studio-video-6-edit-footer",
      },
      { path: "layers", element: <StudioLayers /> },
      {
        path: "store",
        element: <StudioStore />,
        id: "studio-video-ready-33",
      },
      {
        path: "elements",
        element: <StudioElements />,
        id: "studio-ready-6",
      },
      {
        path: "icons",
        element: <StudioElementsDs />,
        id: "studio-ready-7",
      },
      { path: "texts", element: <StudioTexts />, id: "studio-ready-8" },
      { path: "photos", element: <StudioPhotos />, id: "studio-ready-9" },
      {
        path: "upload",
        element: <StudioUpload />,
        id: "studio-ready-10",
      },
      {
        path: "resize",
        element: <StudioResize />,
        id: "studio-ready-16",
      },
      {
        path: "properties",
        element: <StudioProperties />,
        id: "studio-ready-17",
      },
    ],
  },
  {
    id: "hidden-menu-model-edit",
    path: "/model/edit/:key/:id",
    errorElement: <Error />,
    element: (
      <Layout>
        <ModelEdit />
      </Layout>
    ),
    children: [
      {
        path: "backgrounds",
        element: <StudioArt />,
        id: "studio-ready-20",
      },
      { path: "tags", element: <StudioTags />, id: "studio-ready-28" },
      { path: "layers", element: <StudioLayers /> },
      {
        path: "elements",
        element: <StudioElements />,
        id: "studio-ready-21",
      },
      {
        path: "icons",
        element: <StudioElementsDs />,
        id: "studio-ready-22",
      },
      { path: "texts", element: <StudioTexts />, id: "studio-ready-23" },
      { path: "photos", element: <StudioPhotos />, id: "studio-ready-24" },
      {
        path: "upload",
        element: <StudioUpload />,
        id: "studio-ready-25",
      },
      {
        path: "resize",
        element: <StudioResize />,
        id: "studio-ready-26",
      },
      {
        path: "properties",
        element: <StudioProperties />,
        id: "studio-ready-27",
      },
    ],
  },
  {
    id: `hidden-menu-studio-canva`,
    path: "/studio/canva",
    errorElement: <Error />,
    element: (
      <Layout>
        <StudioCanva />
      </Layout>
    ),
  },
  // {
  //   path: "/art-chat",
  //   element: (
  //     <Layout>
  //       <StudioChat />
  //     </Layout>
  //   ),
  // },
  {
    id: "hidden-menu-view-professional",
    path: "/professional",
    errorElement: <Error />,
    element: (
      <Layout>
        <Professional />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-professional-create",
    path: "/professional/create",
    errorElement: <Error />,
    element: (
      <Layout>
        <ProfessionalCreate />
      </Layout>
    ),
    children: [
      /* { path: "ready", element: <ProfessionalCreateTexts /> }, */
      { path: "store", element: <ProfessionalCreateStore /> },
      {
        path: "elements",
        element: <StudioElements />,
        id: "studio-ready-11",
      },
      {
        path: "icons",
        element: <StudioElementsDs />,
        id: "studio-ready-12",
      },
      { path: "texts", element: <StudioTexts />, id: "studio-ready-13" },
      {
        path: "photos",
        element: <StudioPhotos />,
        id: "studio-ready-14",
      },
      {
        path: "upload",
        element: <StudioUpload />,
        id: "studio-ready-15",
      },
    ],
  },
  {
    id: "-sideCreate",
    path: "/overview",
    errorElement: <Error />,
    element: (
      <Layout>
        <Overview />
      </Layout>
    ),
  },
  {
    id: "hidden-segments",
    path: "/segments",
    errorElement: <Error />,
    element: <SegmentSelect />,
  },
  {
    id: `${
      (smartLocal || martinsLocal) && "hidden-"
    }6-add-divider-mobile-sideCreate`,
    path: "/campaigns",
    errorElement: <Error />,
    element: (
      <Layout>
        <Campaigns />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-view-campaign",
    path: "/campaigns/:id",
    errorElement: <Error />,
    element: (
      <Layout>
        <ViewCampaign />
      </Layout>
    ),
  },

  {
    id: "-sideConfig",
    path: "/account",
    errorElement: <Error />,
    element: (
      <Layout>
        <Account />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-account",
    path: "/account-settings",
    element: <Navigate to="/account" replace />,
    errorElement: <Error />,
  },
  {
    id: `${(smartLocal || martinsLocal) && "hidden-"}whatsapp-sideConfig`,
    path: "/whatsapp",
    errorElement: <Error />,
    element: (
      <Layout>
        <WhatsApp />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-view-whatsapp",
    path: "/whatsapp/:id",
    errorElement: <Error />,
    element: (
      <Layout>
        <ViewWhatsApp />
      </Layout>
    ),
  },
  {
    id: "social-sideConfig",
    path: "/social-networks",
    errorElement: <Error />,
    element: (
      <Layout>
        <SocialNetworks />
      </Layout>
    ),
  },

  {
    id: `${
      (smartLocal || martinsLocal) && "hidden-"
    }10-add-divider-radio-sideConfig`,
    path: "/radio",
    errorElement: <Error />,
    element: (
      <Layout>
        <Radio />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-view-radio",
    path: "/radio/:id",
    errorElement: <Error />,
    element: (
      <Layout>
        <ViewRadio />
      </Layout>
    ),
  },
  {
    path: "/site/create",
    id: "hidden-menu-create-site",
    errorElement: <Error />,
    element: (
      <Layout>
        <SiteCreate />
      </Layout>
    ),
    children: [
      { path: "description", element: <SiteDescription /> },
      { path: "offers", element: <SiteOffers /> },
      { path: "links", element: <SiteLinks /> },
      { path: "address", element: <SiteAddress /> },
      { path: "colors", element: <SiteColors /> },
      { path: "fonts", element: <SiteFonts /> },
      { path: "social", element: <SiteSocial /> },
    ],
  },
  {
    path: "/site/edit/:id",
    id: "hidden-menu-edit-site",
    errorElement: <Error />,
    element: (
      <Layout>
        <SiteEdit />
      </Layout>
    ),
    children: [
      { path: "description", element: <SiteDescription /> },
      { path: "offers", element: <SiteOffers /> },
      { path: "links", element: <SiteLinks /> },
      { path: "address", element: <SiteAddress /> },
      { path: "colors", element: <SiteColors /> },
      { path: "fonts", element: <SiteFonts /> },
      { path: "social", element: <SiteSocial /> },
    ],
  },
  {
    path: "/site",
    id: "site-sideConfig",
    errorElement: <Error />,
    element: (
      <Layout>
        <Site />
      </Layout>
    ),
  },
  {
    path: "/profile-business",
    id: `${!isLocalhost && "hidden-menu-"}profile-business-sideConfig`,
    errorElement: <Error />,
    element: (
      <Layout>
        <ProfileBusiness />
      </Layout>
    ),
  },

  // {
  //   path: "/training",
  //   errorElement: <Error />,
  //   element: (
  //     <Layout>
  //       <Training />
  //     </Layout>
  //   ),
  // },
  {
    id: "10-add-divider-radio-sideHelp",
    path: "/learning",
    errorElement: <Error />,
    element: (
      <Layout>
        <Learning />
      </Layout>
    ),
  },
  {
    id: "10-add-divider-training-sideHelp",
    path: "/training",
    errorElement: <Error />,
    element: (
      <Layout>
        <Training />
      </Layout>
    ),
  },
  {
    id: "10-add-divider-calls-sideHelp",
    path: "/calls",
    errorElement: <Error />,
    element: (
      <Layout>
        <Calls />
      </Layout>
    ),
  },
  {
    id: `${(smartLocal || martinsLocal) && "hidden-menu-"}`,
    path: "/financial",
    errorElement: <Error />,
    element: (
      <Layout>
        <Financial />
      </Layout>
    ),
  },
  {
    id: "hidden-default-influencer",
    path: "/influencer/:id",
    errorElement: <Error />,
    element: (
      <Layout>
        <Influencers />
      </Layout>
    ),
  },
  {
    id: "hidden-default-error",
    path: "*",
    errorElement: <Error />,
    element: (
      <Layout>
        <NotFound />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-distribution",
    path: "/distribution",
    errorElement: <Error />,
    element: (
      <Layout>
        <Distribution />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-subscription",
    path: "/subscription",
    errorElement: <Error />,
    element: (
      <Layout>
        <Subscription />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-cancel",
    path: "/cancel",
    errorElement: <Error />,
    element: (
      <Layout>
        <CancelSubscription />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-payment",
    path: "/payment",
    errorElement: <Error />,
    element: (
      <Layout>
        <Payment />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-expired",
    path: "/paymentExpired",
    errorElement: <Error />,
    element: (
      <Layout>
        <PaymentExpired />
      </Layout>
    ),
  },
  {
    id: "hidden-menu-onboarding",
    path: "/onboarding",
    errorElement: <Error />,
    element: <OnBoarding />,
  },
]);
