const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const numberCurrency = (value: number) => {
  return formatter.format(value);
};

export function formatDocument(value: string) {
  if (!value) return "";
  if (value.length <= 11) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2");
  }
  if (value.length >= 14) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
      .replace(/(-\d{2})\d+?$/, "$1");
  }

  return value.replace(/\D/g, "");
}

export const currencyNumber = (value: any) => {
  // Verifica se o valor é válido
  if (!value || typeof value !== "string") {
    return 0;
  }

  // Remove os símbolos de moeda e espaços
  const cleanedValue = value
    .replace(/[^0-9.,]/g, "") // Remove tudo que não é número, ponto ou vírgula
    .replace(/\.(?=.*\.)/, "") // Remove pontos extras (para evitar múltiplos pontos)
    .replaceAll(".", "")
    .replace(",", "."); // Troca vírgulas por pontos para a conversão

  // Converte o valor para número
  const numericValue = parseFloat(cleanedValue);

  // Retorna o número, ou 0 se não for um número válido
  return isNaN(numericValue) ? 0 : numericValue;
};
