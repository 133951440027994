const dynamics = [
  {
    value: "preco_unico",
    label: "Preço único",
    label2: "Preço",
  },
  {
    value: "de_por",
    label: "De - Por",
    label1: "De",
    label2: "Por",
  },
  {
    value: "varejo_atacado",
    label: "Varejo - Atacado",
    label1: "Varejo",
    label2: "Atacado",
  },
  {
    value: "pack_unitario",
    label: "Pack - Unitário",
    label1: "Pack",
    label2: "Unitário",
  },
  {
    value: "normal_clube",
    label: "Normal - Clube",
    label1: "Normal",
    label2: "Clube",
  },
  {
    value: "normal_cartao",
    label: "Normal - Cartão",
    label1: "Normal",
    label2: "Cartão",
  },
  {
    value: "limpo",
    label: "Dois preços",
    label1: "Preço 1",
    label2: "Preço 2",
  },
];

export const dynamicsTags = {
  preco_unico:
    "https://s3.amazonaws.com/datasalesio-imagens/etiquetas/EtiquetaSombra_Vermelhab474c01a-74d9-a696-ac71-1746edae1549.png",
  de_por:
    "https://s3.amazonaws.com/datasalesio-imagens/etiquetas/EtiquetaDePor_Vermelhaf31e900e-808a-2c60-0b9c-4b07ab1da142.png",
  varejo_atacado:
    "https://s3.amazonaws.com/datasalesio-imagens/etiquetas/EtiquetaVarejo_Vermelhod7e973d9-2dda-a718-ef33-f76162df23b7.png",
  pack_unitario:
    "https://s3.amazonaws.com/datasalesio-imagens/etiquetas/EtiquetaPack_Vermelho3e557bb7-c4fa-b375-69cd-9959abb69115.png",
  normal_clube:
    "https://s3.amazonaws.com/datasalesio-imagens/etiquetas/EtiquetaClube_Vermelhoac216e89-734f-d8bb-042b-446808767add.png",
  normal_cartao:
    "https://s3.amazonaws.com/datasalesio-imagens/etiquetas/EtiquetaCartao_Vermelhocbfc6afd-6796-7208-ec1f-a2eba47b213d.png",
};

export const transformDynamics = (dynamic: string) => {
  const name = dynamics.find(({ value }) => value === dynamic);

  if (name) {
    return name.label;
  }

  return "Preço único";
};

export default dynamics;
