import { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React, { createContext, useCallback, useMemo, useState } from "react";
import useTheme from "@mui/styles/useTheme";
import { Theme } from "@mui/material/styles";
import Dialog from "../components/Dialog";
import Typography from "@mui/material/Typography";

type DialogConfirmationProvidesProps = {
  children: React.ReactNode;
};

type DialogConfirmationOptions = {
  loading?: boolean;
  disabled?: boolean;
  confirmateLabel?: string;
  cancelLabel?: string;
  cancelLabelId?: string;
  onConfirmate?: () => void;
  onCancel?: () => void;
};

export type DialogConfirmationContextData = {
  open: boolean;
  isLoading?: boolean;
  show: (
    title: string,
    message: string,
    options?: DialogConfirmationOptions
  ) => void;
  close: () => void;
  loading: (status: boolean) => void;
};

export const DialogConfirmationContext =
  createContext<DialogConfirmationContextData>(
    {} as DialogConfirmationContextData
  );

export function DialogConfirmationProvider({
  children,
}: DialogConfirmationProvidesProps) {
  const theme: Theme = useTheme();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [options, setOptions] = useState<DialogConfirmationOptions>();
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const show = useCallback(
    async (
      title: string,
      message: string,
      options?: DialogConfirmationOptions
    ) => {
      if (options) {
        setOptions(options);
      }
      setMessage(message);
      setTitle(title);
      setIsVisible(true);
    },
    []
  );

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const loading = useCallback((status: boolean) => {
    setOptions((options) => ({ ...options, loading: status }));
  }, []);

  const handleCancel = useCallback(() => {
    close();
    if (Boolean(options) && Boolean(options?.onCancel)) {
      //@ts-ignore
      options.onCancel();
    }
  }, [options, close]);

  const toastContextData = useMemo(() => {
    return {
      show,
      close,
      loading,
      open: isVisible,
      isLoading: options?.loading,
    };
  }, [isVisible, close, show, loading]);

  const actions = useMemo(() => {
    const payload: ButtonProps[] = [
      {
        ...(options?.cancelLabelId ? { id: options?.cancelLabelId } : {}),
        children: options?.cancelLabel || "Cancelar",
        onClick: handleCancel,
        disabled: options?.disabled || options?.loading,
        color: "inherit",
      },
    ];
    if (Boolean(options?.onConfirmate)) {
      const confirmate: ButtonProps = {
        children: options?.loading ? (
          <CircularProgress size={theme.typography.body1.fontSize} />
        ) : (
          options?.confirmateLabel || "Confirmar"
        ),
        variant: "contained",
        onClick: options?.onConfirmate,
        disabled: options?.disabled || options?.loading,
      };
      payload.push(confirmate);
    }

    return payload;
  }, [options]);

  return (
    <DialogConfirmationContext.Provider value={toastContextData}>
      {children}
      <Dialog
        maxWidth="xs"
        fullWidth
        title={title}
        actions={actions}
        open={isVisible}
        aria-labelledby="alert-dialog-confirmation"
        aria-describedby="alert-dialog-description-confirmation"
      >
        <Typography style={{ whiteSpace: "pre-line" }}>{message}</Typography>
      </Dialog>
    </DialogConfirmationContext.Provider>
  );
}
