import {
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  sub,
  add,
  Duration,
  parseISO,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import { createContext, useMemo } from "react";

type DateProviderProps = {
  children: React.ReactNode;
};

export type DateContextData = {
  difference: (date: string) => string;
  formatted: (date: string, formatTo?: string) => string;
  formattedV2: (date: string | Date, formatTo?: string) => string;
  subDate: (date: Date, duration: Duration, formatTo?: string) => string | Date;
  addDate: (date: Date, duration: Duration, formatTo?: string) => string | Date;
  formattedDate: (date: Date, formatTo: string) => string;
  weekdays: { name: string; value: number; name2: string }[];
};

export const DateContext = createContext<DateContextData>(
  {} as DateContextData
);

export function DateProvider({ children }: DateProviderProps) {
  const capitalizeFirstLetter = (dateString: string) => {
    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
  };

  const difference = (date: string) => {
    const newDate =
      date && date.includes("Z") ? date.replace("Z", "") : new Date();
    const now = new Date();
    const createdDate = new Date(newDate);

    let diff = differenceInMinutes(now, new Date(createdDate));

    if (diff === 0) {
      return "Agora";
    } else if (diff > 0 && diff < 60) {
      return `há ${diff} minutos`;
    } else {
      diff = differenceInHours(now, new Date(createdDate));
      if (diff < 24) {
        return `há ${diff} horas`;
      } else {
        diff = differenceInDays(now, new Date(createdDate));
        if (diff < 7) {
          return `há ${diff} dias`;
        } else {
          return `${format(new Date(createdDate), "dd 'de' MMMM',' yyyy", {
            locale: ptBR,
          })}`;
        }
      }
    }
  };

  const formatted = (
    date: string,
    formatTo = "dd 'de' MMMM 'de' yyyy 'às' HH:mm"
  ) => {
    if (date) {
      const newDate = date.includes("Z") ? date.replace("Z", "") : new Date();
      return format(new Date(newDate), formatTo, { locale: ptBR });
    }

    return format(new Date(), formatTo, { locale: ptBR });
  };

  const formattedV2 = (
    date: string | Date,
    formatTo = "dd 'de' MMMM 'de' yyyy 'às' HH:mm"
  ) => {
    const newDate = typeof date === "string" ? parseISO(date) : date;
    return capitalizeFirstLetter(
      format(new Date(newDate), formatTo, { locale: ptBR })
    );
  };

  const subDate = (date: Date, duration: Duration, formatTo?: string) => {
    if (formatTo) {
      return format(sub(date, duration), formatTo);
    } else {
      return sub(date, duration);
    }
  };

  const addDate = (date: Date, duration: Duration, formatTo?: string) => {
    if (formatTo) {
      return format(add(date, duration), formatTo);
    } else {
      return add(date, duration);
    }
  };

  const formattedDate = (date: Date, formatTo: string) => {
    return format(date, formatTo);
  };

  const weekdays = useMemo(() => {
    const startWeek = startOfWeek(new Date(), { weekStartsOn: 0 });
    const endWeek = endOfWeek(new Date(), { weekStartsOn: 0 });

    return eachDayOfInterval({ start: startWeek, end: endWeek }).map(
      (date) => ({
        name: capitalizeFirstLetter(format(date, "eeee", { locale: ptBR })),
        value: Number(format(date, "i", { locale: ptBR })),
        name2: format(date, "EEEE"),
      })
    );
  }, []);

  const dateContextData = useMemo(() => {
    return {
      weekdays,
      difference,
      formatted,
      formattedDate,
      formattedV2,
      subDate,
      addDate,
    };
  }, [
    difference,
    formatted,
    formattedDate,
    formattedV2,
    subDate,
    addDate,
    weekdays,
  ]);

  return (
    <DateContext.Provider value={dateContextData}>
      {children}
    </DateContext.Provider>
  );
}
