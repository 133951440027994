import {
  BlobServiceClient,
  BlockBlobParallelUploadOptions,
} from "@azure/storage-blob";
import { createContext, useCallback, useMemo } from "react";
import { useAuth } from "../hooks/useAuth";

type AzureStorageProviderProps = {
  children: React.ReactNode;
};

export type AzureStorageUploadS3 = {
  folderName: string;
  subFolderName?: string;
  file: Buffer | Uint8Array | Blob;
  name?: string;
  distinctName?: boolean;
  type?: string;
  extension?: string;
  container: string;
};

export type AzureStorageContextData = {
  upload: (media: AzureStorageUploadS3) => any;
  urlAssigned: (mediaUrl?: string) => Promise<string>;
};

export const AzureStorageContext = createContext<AzureStorageContextData>(
  {} as AzureStorageContextData
);

export function AzureStorageProvider({ children }: AzureStorageProviderProps) {
  const { token } = useAuth();

  const urlAssigned = async (mediaUrl?: string): Promise<string> => {
    try {
      const containerName =
        mediaUrl?.split("core.windows.net/")[1].split("/")[0] || "";
      const params = new URLSearchParams({ containerName });
      const response = await fetch(
        `https://flyers.azurewebsites.net/sas-token?${params.toString()}`,
        { method: "GET", headers: { Authorization: token } }
      );
      const data = await response.json();
      if (mediaUrl) {
        return `${mediaUrl}?${data.token}`;
      } else {
        return data.token;
      }
    } catch {
      return mediaUrl || "";
    }
  };

  const upload = useCallback(
    async (media: AzureStorageUploadS3): Promise<string> => {
      try {
        const params = new URLSearchParams({ containerName: media.container });
        const response = await fetch(
          `https://flyers.azurewebsites.net/sas-token?${params.toString()}`,
          { method: "GET", headers: { Authorization: token } }
        );
        const data = await response.json();

        const blobServiceClient = new BlobServiceClient(
          `${data.containerUrl}?${data.token}`
        );

        const timestamp = new Date().getTime();

        const fileName = media.distinctName
          ? `${timestamp}`
          : String(media.name);

        const folderFilesKey = `${
          media.folderName ? `${media.folderName}/` : ""
        }${media.subFolderName ? `${media.subFolderName}/` : ""}`;

        const fileKey = `${folderFilesKey}${encodeURIComponent(fileName)}.${
          media.extension
        }`;

        const options: BlockBlobParallelUploadOptions = {
          blobHTTPHeaders: {
            blobContentType: media.type,
          },
        };

        const containerClient = blobServiceClient.getContainerClient("");
        const blobClient = containerClient.getBlockBlobClient(fileKey);

        await blobClient.uploadData(media.file, options);

        const Location = `${data.containerUrl}/${fileKey}`;

        return Promise.resolve(Location);
      } catch (err) {
        console.error("DS ERROR => ", err);
        return Promise.reject("Error upload Azure Storage");
      }
    },
    [token]
  );

  const azureStorageContextData = useMemo(() => {
    return { upload, urlAssigned };
  }, [upload, urlAssigned]);

  return (
    <AzureStorageContext.Provider value={azureStorageContextData}>
      {children}
    </AzureStorageContext.Provider>
  );
}
