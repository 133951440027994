import { useContext } from "react";

import { AwsContext, AwsContextData } from "../contexts/Aws";

export function useAws(): AwsContextData {
  const context = useContext(AwsContext);

  if (!context) {
    throw new Error("useAws must be used within an AwsProvider");
  }

  return context;
}
