import React, { createContext, useCallback, useMemo, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

type ToastProviderProps = {
  children: React.ReactNode;
};

export type ToastContextData = {
  show: (message: string, options?: AlertProps) => void;
  close: () => void;
};

export const ToastContext = createContext<ToastContextData>(
  {} as ToastContextData
);

export function ToastProvider({ children }: ToastProviderProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState<AlertProps>({} as AlertProps);

  const show = useCallback(
    async (toastMessage: string, toastOptions?: AlertProps) => {
      setMessage(toastMessage);
      if (toastOptions) setOptions(toastOptions);
      setIsVisible(true);
    },
    []
  );

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const toastContextData = useMemo(() => {
    return {
      show,
      close,
    };
  }, [close, show]);

  return (
    <ToastContext.Provider value={toastContextData}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isVisible}
        autoHideDuration={3500}
        onClose={close}
      >
        <Alert
          {...options}
          onClose={close}
          variant="filled"
          sx={{ whiteSpace: "pre-line", width: "100%" }}
        >
          <Typography variant="subtitle2" color="#FFF">
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
}
