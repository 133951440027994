import React, { createContext, useMemo, useState } from "react";

type PortalProviderProps = {
  children: React.ReactNode;
};

export type PortalContextData = {
  handleChangeStatusSidebar(): void;
  handleChangeStatusMenu(): void;
  handleChangeStatusCreate(): void;
  isSidebarOpen: boolean;
  openMenu: boolean;
  openCreate: boolean;
};

export const PortalContext = createContext<PortalContextData>(
  {} as PortalContextData
);

export function PortalProvider({ children }: PortalProviderProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  const handleChangeStatusSidebar = () => {
    setOpen((prev) => !prev);
  };

  const handleChangeStatusMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  const handleChangeStatusCreate = () => {
    setOpenCreate((prev) => !prev);
  };

  const PortalContextData = useMemo(() => {
    return {
      isSidebarOpen: open,
      openCreate,
      openMenu,
      handleChangeStatusSidebar,
      handleChangeStatusMenu,
      handleChangeStatusCreate,
    };
  }, [
    open,
    openCreate,
    openMenu,
    handleChangeStatusSidebar,
    handleChangeStatusMenu,
    handleChangeStatusCreate,
  ]);

  return (
    <PortalContext.Provider value={PortalContextData}>
      {children}
    </PortalContext.Provider>
  );
}
