import loadable from "@loadable/component";
import { createBrowserRouter, Navigate } from "react-router-dom";

const Layout = loadable(() => import("../layout"));
const SignAsToken = loadable(() => import("../pages/SignAsToken"));
const SignAsProfessionalArt = loadable(
  () => import("../pages/SignAsProfessionalArt")
);

const ProfessionalCreate = loadable(
  () => import("../pages/Professional/Create")
);
const ProfessionalCreateTexts = loadable(
  () => import("../pages/Professional/Create/Texts")
);
const ProfessionalCreateStore = loadable(
  () => import("../pages/Professional/Create/Store")
);

const Error = loadable(() => import("../pages/Error"));
const SignAsWhitelabel = loadable(() => import("../pages/SignAsWhitelabel"));
const SignAsBackground = loadable(() => import("../pages/SignAsBackground"));
const SignAsEditArt = loadable(() => import("../pages/SignAsEditArt"));
const Projects = loadable(() => import("../pages/Projects"));
const ViewProject = loadable(() => import("../pages/Projects/View"));
const Studio = loadable(() => import("../pages/Studio"));
const Campaigns = loadable(() => import("../pages/Campaigns"));
const ViewCampaign = loadable(() => import("../pages/Campaigns/View"));
const NotFound = loadable(() => import("../pages/NotFound"));
const WhatsApp = loadable(() => import("../pages/WhatsApp"));
const SignAsRedirect = loadable(() => import("../pages/SignAsRedirect"));
const Radio = loadable(() => import("../pages/Radio"));
const Overview = loadable(() => import("../pages/Overview"));
const StudioEdit = loadable(() => import("../pages/Studio/Edit"));
const StudioArt = loadable(() => import("../pages/Studio/components/Art"));
const StudioProducts = loadable(
  () => import("../pages/Studio/components/Products")
);
const StudioLayout = loadable(
  () => import("../pages/Studio/components/Layout")
);
const StudioDescription = loadable(
  () => import("../pages/Studio/components/Description")
);
const StudioTags = loadable(() => import("../pages/Studio/components/Tags"));
const StudioColors = loadable(
  () => import("../pages/Studio/components/Colors")
);
const StudioFooter = loadable(
  () => import("../pages/Studio/components/Footer")
);
const StudioStore = loadable(() => import("../pages/Studio/components/Store"));
const StudioElements = loadable(
  () => import("../pages/Studio/components/Elements")
);
const StudioElementsDs = loadable(
  () => import("../pages/Studio/components/ElementsDs")
);
const StudioTexts = loadable(() => import("../pages/Studio/components/Texts"));
const StudioLayers = loadable(
  () => import("../pages/Studio/components/Layers")
);
const StudioPhotos = loadable(
  () => import("../pages/Studio/components/Photos")
);
const StudioUpload = loadable(
  () => import("../pages/Studio/components/Upload")
);
const StudioResize = loadable(
  () => import("../pages/Studio/components/Resize")
);
const StudioProperties = loadable(
  () => import("../pages/Studio/components/Properties")
);
const StudioCreate = loadable(() => import("../pages/Studio/Create"));
const StudioCanva = loadable(() => import("../pages/Studio/Canva"));

export const router = createBrowserRouter([
  {
    id: "hidden-menu-initial",
    path: "/",
    element: <Navigate to="/studio" replace />,
  },
  {
    id: "hidden-sign-token",
    path: "/sign-as-user/:token",
    element: <SignAsToken />,
  },
  {
    path: "/sign-as-whitelabel/:token",
    element: <SignAsWhitelabel />,
  },
  {
    path: "/sign-as-whitelabel/:token/:formatId",
    element: <SignAsWhitelabel />,
  },
  {
    path: "/sign-as-whitelabel/:token/:formatId/:products",
    element: <SignAsWhitelabel />,
  },
  {
    path: "/sign-as-professional/:token/:art",
    element: <SignAsProfessionalArt />,
  },
  {
    path: "/sign-as-whitelabel/backgrounds/:token/:formatId/:background",
    element: <SignAsBackground />,
  },
  {
    path: "/sign-as-whitelabel/backgrounds/:token/:formatId/:background/:products",
    element: <SignAsBackground />,
  },
  {
    path: "/sign-as-redirect/:token/:redirect",
    element: <SignAsRedirect />,
  },
  {
    path: "/sign-as-edit/:token/:imageId/:offerId",
    element: <SignAsEditArt />,
  },
  {
    path: "/sign-as-edit/:token/:imageId/:offerId/:products",
    element: <SignAsEditArt />,
  },
  {
    path: "/projects",
    element: (
      <Layout>
        <Projects />
      </Layout>
    ),
  },
  {
    path: "/projects/:id",
    id: "hidden-menu-view-projects",
    element: (
      <Layout>
        <ViewProject />
      </Layout>
    ),
  },
  {
    path: "/studio",
    element: (
      <Layout>
        <Studio />
      </Layout>
    ),
  },
  {
    path: "/studio/create",
    element: (
      <Layout>
        <StudioCreate />
      </Layout>
    ),
    children: [
      {
        path: "backgrounds",
        element: <StudioArt />,
        id: "studio-video-ready-1",
      },
      {
        path: "products",
        element: <StudioProducts />,
        id: "studio-video-ready-2",
      },
      { path: "layout", element: <StudioLayout />, id: "studio-video-2" },
      {
        path: "description",
        element: <StudioDescription />,
        id: "studio-video-3",
      },
      { path: "tags", element: <StudioTags />, id: "studio-video-4" },
      { path: "colors", element: <StudioColors />, id: "studio-video-5" },
      { path: "footer", element: <StudioFooter />, id: "studio-video-6" },
      { path: "layers", element: <StudioLayers /> },
      {
        path: "store",
        element: <StudioStore />,
        id: "studio-video-ready-3",
      },
      {
        path: "elements",
        element: <StudioElements />,
        id: "studio-ready-1",
      },
      {
        path: "icons",
        element: <StudioElementsDs />,
        id: "studio-ready-2",
      },
      { path: "texts", element: <StudioTexts />, id: "studio-ready-3" },
      { path: "photos", element: <StudioPhotos />, id: "studio-ready-4" },
      { path: "upload", element: <StudioUpload />, id: "studio-ready-5" },
      {
        path: "resize",
        element: <StudioResize />,
        id: "studio-ready-19",
      },
    ],
  },
  {
    id: "hidden-menu-studio-edit",
    path: "/studio/edit/:key/:id",
    element: (
      <Layout>
        <StudioEdit />
      </Layout>
    ),
    children: [
      {
        path: "backgrounds",
        element: <StudioArt />,
        id: "studio-video-ready-7",
      },
      {
        path: "products",
        element: <StudioProducts />,
        id: "studio-video-ready-8",
      },
      { path: "tags", element: <StudioTags />, id: "studio-video-9" },
      {
        path: "elements",
        element: <StudioElements />,
        id: "studio-ready-6",
      },
      {
        path: "icons",
        element: <StudioElementsDs />,
        id: "studio-ready-7",
      },
      { path: "texts", element: <StudioTexts />, id: "studio-ready-8" },
      { path: "photos", element: <StudioPhotos />, id: "studio-ready-9" },
      {
        path: "upload",
        element: <StudioUpload />,
        id: "studio-ready-10",
      },
      {
        path: "resize",
        element: <StudioResize />,
        id: "studio-ready-16",
      },
      {
        path: "properties",
        element: <StudioProperties />,
        id: "studio-ready-17",
      },
    ],
  },
  {
    id: "hidden-menu-studio-canva",
    path: "/studio/canva",
    element: (
      <Layout>
        <StudioCanva />
      </Layout>
    ),
  },
  {
    path: "/campaigns",
    id: "6-add-divider",
    element: (
      <Layout>
        <Campaigns />
      </Layout>
    ),
  },

  {
    id: "hidden-menu-professional-create",
    path: "/professional/create",
    errorElement: <Error />,
    element: (
      <Layout>
        <ProfessionalCreate />
      </Layout>
    ),
    children: [
      /* { path: "ready", element: <ProfessionalCreateTexts /> }, */
      { path: "store", element: <ProfessionalCreateStore /> },
      {
        path: "elements",
        element: <StudioElements />,
        id: "studio-ready-11",
      },
      {
        path: "icons",
        element: <StudioElementsDs />,
        id: "studio-ready-12",
      },
      { path: "texts", element: <StudioTexts />, id: "studio-ready-13" },
      {
        path: "photos",
        element: <StudioPhotos />,
        id: "studio-ready-14",
      },
      {
        path: "upload",
        element: <StudioUpload />,
        id: "studio-ready-15",
      },
    ],
  },
  {
    path: "/campaigns/:id",
    id: "hidden-menu-view-campaign",
    element: (
      <Layout>
        <ViewCampaign />
      </Layout>
    ),
  },
  {
    path: "/overview",
    element: (
      <Layout>
        <Overview />
      </Layout>
    ),
  },
  {
    id: "10-add-divider",
    path: "/radio",
    element: (
      <Layout>
        <Radio />
      </Layout>
    ),
  },
  {
    path: "/whatsapp",
    element: (
      <Layout>
        <WhatsApp />
      </Layout>
    ),
  },
  {
    id: "hidden-default-error",
    path: "*",
    element: (
      <Layout>
        <NotFound />
      </Layout>
    ),
  },
]);
