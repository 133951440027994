import { v4 } from "uuid";
import { StoreType } from "polotno/model/store";
import { makeId } from "./stringIdGenerate";
import { numberCurrency } from "./currencyFormat";
import { Products } from "../types/studio";
import extractXY from "./extractXY";
import { dynamicsTags } from "./commercialDynamics";
import adjustCaseFont, { adjustCaseFontReturnString } from "./adjustCaseFont";
import { findUnits, unitReplace } from "./productsText";

export default (
  polotnoPage: any,
  polotnoPageDefault: any,
  templatePage: any,
  nPage: any,
  attributes: any
) => {
  const polotnoCurrentChildren = polotnoPage?.children?.map((child: any) => ({
    ...child,
    id: makeId(16),
  }));
  const polotnoDefaultChildren = polotnoPageDefault?.children?.map(
    (child: any) => ({
      ...child,
      id: makeId(16),
    })
  );

  const polotnoChildren = polotnoCurrentChildren || polotnoDefaultChildren;

  let templateChildren = templatePage?.children?.map((child: any) => ({
    ...child,
    id: makeId(16),
  }));

  const footer = polotnoChildren.filter((child: any) =>
    child?.custom?.v4?.includes("footer")
  );

  if (footer?.length > 0) {
    templateChildren = templateChildren.filter(
      (child: any) => child?.custom?.name !== "rodape"
    );
  }

  const elements = polotnoCurrentChildren?.filter(
    (child: any) =>
      child?.custom?.name?.includes("icon") ||
      child?.custom?.name?.includes("upload")
  );

  // const elementsDynamics = polotnoCurrentChildren?.filter(
  //   (child: any) =>
  //     child?.custom?.name?.includes("proibido") ||
  //     child?.custom?.name?.includes("desconto")
  // );

  let product: any;

  const children = templateChildren
    .map((child: any) => {
      const name = child?.custom?.name;
      const indexProduct = Number(name?.slice(-2));
      const polotno = polotnoChildren?.find(
        (temp: any) => temp?.custom?.name === name
      );

      const newChild = { ...child };

      if (
        !isNaN(indexProduct) &&
        (name.includes("produto-") ||
          name.includes("etiqueta-") ||
          name.startsWith("var"))
      ) {
        product = nPage[indexProduct - 1];

        if (Boolean(product)) {
          return setProduct(newChild, product, indexProduct, name, attributes);
        }

        return newChild;
      }

      if (
        name === "rodape" ||
        name === "logo" ||
        name === "descricao" ||
        name === "telefone" ||
        name === "endereco" ||
        name === "descritivo-texto-001" ||
        name === "texto-auxiliar-01"
      ) {
        const newChild =
          attributes?.type === "modelos-prontos" ? child : child || polotno;

        if (Boolean(newChild.text)) {
          return {
            ...newChild,
            fontFamily: Boolean(attributes?.footerFontFamily)
              ? attributes?.footerFontFamily
              : child.fontFamily,
            id: makeId(16),
            text: adjustCaseFontReturnString(
              attributes?.fontCase,
              newChild.text
            ),
            custom: { ...newChild?.custom, default: "template" },
          };
        } else {
          return {
            ...newChild,
            id: makeId(16),
            custom: { ...newChild?.custom, default: "template" },
          };
        }
      }

      return {
        ...child,
        id: makeId(16),
        custom: { ...child.custom, default: "template" },
      };
    })
    .filter(Boolean);

  return { elements: elements || [], footer, children };
};

export const adjustLayoutTagsCustom = (
  store: any,
  page: any,
  elements: any[]
) => {
  const _page = page.toJSON();
  const _elements = elements[0];
  const removeElements: any[] = [];
  const description = _page?.children?.filter(
    (child: any) =>
      child?.custom?.name?.includes("produto-preco-por-") ||
      child?.custom?.name?.includes("produto-preco-de-") ||
      child?.custom?.name?.includes("produto-medida-")
  );

  _page?.children.forEach((child: any) => {
    const name = child?.custom?.name;
    const product = child?.custom?.values;

    if (Boolean(child?.custom?.v4?.includes("tag"))) {
      removeElements.push(child.id);
    }

    if (name?.includes("etiqueta-") && product.preco_original) {
      const productQuantityPage = Number(name?.slice(-2));
      const childrenPage = _page?.children?.filter((el: any) =>
        el?.custom?.name?.endsWith(`-${pad(productQuantityPage)}`)
      );

      childrenPage.forEach((el: any) => {
        if (
          !el?.custom?.name?.includes("produto-imagem-") &&
          !el?.custom?.name?.includes("produto-descricao-")
        ) {
          removeElements.push(el.id);
        }
      });

      const {
        total_x: pageX,
        total_y: pageY,
        total_w: pageW,
        total_h: pageH,
      } = extractXY(childrenPage);

      const { total_x: tagX, total_y: tagY } = extractXY(_elements?.children);

      const tempChildren = _elements?.children?.map((child: any) => ({
        ...child,
        id: v4(),
        x: child.x + pageX - tagX,
        y: child.y + pageY - tagY,
      }));
      const { total_w: childrenW, total_h: childrenH } =
        extractXY(tempChildren);
      const fator = (pageW / childrenW) * 1;

      const tags: any[] = [];

      const tagsChildren = _elements.children?.filter(
        (tag: any) =>
          !tag?.custom?.name?.includes("produto-imagem-") &&
          !tag?.custom?.name?.includes("produto-descricao-")
      );

      for (let [index, tag] of tagsChildren.entries()) {
        const element = setProduct(tag, product, 1, tag?.custom?.name);
        const relativeX = (tag.x - pageX) * fator;
        const relativeY = (tag.y - pageY) * fator;
        const position = {
          id: makeId(16),
          ...(tag.fontSize ? { fontSize: tag.fontSize * fator } : {}),
          width: tag.width * fator,
          height: tag.height * fator,
          x: pageX - (childrenW * fator - pageW) / 2 + relativeX,
          y: pageY - (childrenH * fator - pageH) / 2 + relativeY,
          custom: {
            ...(index === 0 ? { name, values: product } : {}),
            v4: "tag",
            name_for_group: `for-group-${pad(productQuantityPage)}`,
            oldTag: { tag: child, description },
          },
        };
        tags.push(position.id);

        if (element) {
          page.addElement({
            ...element,
            ...position,
          });
        } else {
          page.addElement({
            ...tag,
            ...position,
          });
        }
      }
    }
  });

  store.deleteElements(removeElements);
};

export const adjustLayoutFooter = (
  store: any,
  page: any,
  elements: any[],
  description: any,
  custom?: any
) => {
  let currentElement = 0;

  const newElements = elements?.map((element: any) => ({
    ...element,
    children: element?.children?.map((child: any) => {
      let newChild = {
        ...child,
        id: v4(),
        custom: { ...child?.custom, v4: "footer", type: "rodape" },
      };

      if (!Boolean(child?.custom?.name)) {
        currentElement = currentElement + 1;

        return {
          ...newChild,
          custom: {
            ...newChild?.custom,
            name: `element-footer-${pad(currentElement)}`,
          },
        };
      }

      return newChild;
    }),
  }));

  const removeElements = page.children
    ?.filter(
      (child: any) =>
        String(child?.custom?.name).toLowerCase() === "rodape" ||
        String(child?.custom?.name).toLowerCase() === "telefone" ||
        String(child?.custom?.name).toLowerCase() === "endereco" ||
        String(child?.custom?.name).toLowerCase() === "descricao" ||
        String(child?.custom?.type).toLowerCase() === "rodape"
    )
    ?.map((child: any) => child?.id);

  store.deleteElements(removeElements);

  newElements?.forEach((design: any) => {
    const {
      total_w: jsonW,
      total_h: jsonH,
      total_x: jsonX,
      total_y: jsonY,
    } = extractXY(design?.children);
    const fator = (Number(store.width) / jsonW) * 0.98;

    design?.children.forEach((child: any) => {
      const pre_width = child.width * fator;
      const pre_height = child.height * fator;
      const x_relativo = (child.x - jsonX) * fator;
      const y_relativo = (child.y - jsonY) * fator;

      let newChild = {
        ...child,
        x: (store.width - jsonW * fator) / 2 + x_relativo,
        y: store.height - jsonH * fator + y_relativo - 10,
        width: pre_width,
        height: pre_height,
        ...(child.fontSize ? { fontSize: child.fontSize * fator } : {}),
      };

      if (child.type === "text" && Boolean(description[child.custom?.name])) {
        newChild = {
          ...newChild,
          fontFamily: Boolean(custom?.footerFontFamily)
            ? custom?.footerFontFamily
            : newChild.fontFamily,
          text: adjustCaseFontReturnString(
            custom?.fontCase,
            String(description[newChild.custom?.name])
          ),
        };
      }

      page.addElement(newChild);
    });
  });
};

export const adjustLayoutDescription = (
  child: any,
  attributes: any,
  key: string,
  value: string
) => {
  const name = child?.custom?.name;

  if (name === key) {
    if (child.type === "image") {
      child.set({ src: value });
    } else {
      child.set({
        text: adjustCaseFontReturnString(attributes?.fontCase, value),
      });
    }
  } else if (
    key === "descricao" &&
    (name === "descritivo-texto-001" || name === "texto-auxiliar-01")
  ) {
    child.set({
      text: adjustCaseFontReturnString(attributes?.fontCase, value),
    });
  }
};

export const adjustLayoutTags = (
  page: any,
  tagURL?: string,
  tagType?: string
) => {
  page.children.forEach((child: any) => {
    if (
      child?.custom?.name?.includes("etiqueta-") &&
      child?.custom?.v4?.includes("tag")
    ) {
      page.addElement({ ...child?.custom?.oldTag.tag, src: tagURL });
      child?.custom?.oldTag?.description?.forEach((info: any) =>
        page.addElement({ ...info })
      );
    } else if (child?.custom?.name?.includes("etiqueta-")) {
      if (tagType === "all") {
        child.set({ src: tagURL || child.src });
      } else if (
        child?.custom?.values?.dinamica_comercial === tagType ||
        child?.custom?.values?.dynamics === tagType
      ) {
        child.set({ src: tagURL || child.src });
      }
    }
  });
};

export const adjustLayoutTagsMinors = (
  store: StoreType,
  page: any,
  show: boolean,
  product: Products
) => {
  const children = page.children?.filter(
    (child: any) =>
      child?.custom?.values?.id_tabloide_produto === product.id_tabloide_produto
  );

  if (children.length > 0) {
    if (show) {
      const imageProduct = children.find((child: any) =>
        child?.custom?.name?.includes("produto-imagem-")
      );
      if (imageProduct) {
        const sequence = Number(imageProduct?.custom?.name?.slice(-2));

        page.addElement({
          x: imageProduct.x + imageProduct.width - imageProduct.width / 4,
          y: imageProduct.y + 25,
          width: imageProduct.width / 4,
          height: imageProduct.width / 4,
          type: "image",
          src: import.meta.env.VITE_REACT_IMAGE_MINORS,
          custom: { name: `proibido-${sequence}`, values: product },
        });
      }
    } else {
      const tagMinors = children.find((child: any) =>
        child?.custom?.name?.includes("proibido-")
      );

      if (tagMinors) {
        store.deleteElements([tagMinors.id]);
      }
    }
  }
};

export const adjustLayoutTagsDiscount = (
  store: StoreType,
  page: any,
  show: boolean,
  product: Products
) => {
  const children = page.children?.filter(
    (child: any) =>
      child?.custom?.values?.id_tabloide_produto === product.id_tabloide_produto
  );

  if (children.length > 0) {
    if (show) {
      const imageProduct = children.find((child: any) =>
        child?.custom?.name?.includes("produto-imagem-")
      );

      if (imageProduct) {
        const sequence = Number(imageProduct?.custom?.name?.slice(-2));
        const porcentagemDesconto =
          ((product.preco_original - product.preco_dois) /
            product.preco_original) *
          100;

        const tag = page.addElement({
          x: imageProduct.x,
          y: imageProduct.y,
          width: imageProduct.width / 3,
          height: imageProduct.width / 3,
          type: "image",
          src: import.meta.env.VITE_REACT_IMAGE_DISCOUNT,
          shadowEnabled: true,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowOpacity: 1,
          shadowColor: "#000",
          custom: { name: `desconto-${sequence}`, values: product },
        });

        page.addElement({
          x: tag.x, // Centralizado no retângulo
          y: tag.y + tag.width / 3, // Centralizado no retângulo
          fontSize: tag.width / 3, // Ajuste conforme necessário
          width: tag.width,
          text: `-${Math.floor(porcentagemDesconto)}%`,
          fontFamily: "Bebas Neue",
          custom: {
            name: `desconto-texto-${sequence}`,
            values: product,
          },
          type: "text", // Tipo do elemento
          align: "center", // Centralizado
          verticalAlign: "middle", // Centralizado
        });
      }
    } else {
      const tagDiscounts = children
        .filter((child: any) => child?.custom?.name?.includes("desconto-"))
        .map((child: any) => child.id);

      if (Array.isArray(tagDiscounts)) {
        store.deleteElements(tagDiscounts);
      }
    }
  }
};

export const adjustLayoutProducts = (
  page: any,
  product: Products,
  attributes?: any
) => {
  const children = page.children?.filter((child: any) => {
    return (
      child?.custom?.values?.id_tabloide_produto ===
        product.id_tabloide_produto ||
      child?.custom?.values?.nome_produto === product.nome_produto
    );
  });

  if (children.length > 0) {
    children.forEach((child: any) => {
      const name = child?.custom?.name;
      const newCustom = {
        ...child?.custom,
        values: { ...child?.custom?.values, ...product },
      };

      if (name?.includes("etiqueta-")) {
        child?.set({
          custom: newCustom,
          src: setDefaultTagProduct(product.dinamica_comercial, attributes),
        });
      } else if (name?.includes("produto-imagem-")) {
        const image =
          product?.imagem_produto === "error"
            ? import.meta.env.VITE_REACT_IMAGE_NOT_FOUND_PRODUCT
            : product?.imagem_produto ||
              import.meta.env.VITE_REACT_IMAGE_TRANSPARENT;
        child?.set({ src: image, custom: newCustom });
      } else if (
        name?.includes("produto-descricao-") ||
        name?.includes("produto-nome-")
      ) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            String(product?.nome_produto || " ")
          ),
          custom: newCustom,
        });
      } else if (name?.includes(`produto-medida-`)) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            String(product?.medida || " ")
          ),
          custom: newCustom,
        });
      } else if (name?.includes(`produto-preco-de-`)) {
        child?.set({
          text: Boolean(product?.preco_original)
            ? numberCurrency(product.preco_original)
                .replace("R$", "")
                .replace(/\s/g, "")
            : " ",
          custom: newCustom,
        });
      } else if (
        name?.includes(`produto-preco-por-r-`) ||
        name?.includes(`produto-preco-2-reais-`)
      ) {
        child?.set({
          text: numberCurrency(product.preco_dois)
            .replace("R$", "")
            .replace(/\s/g, "")
            .split(",")[0],
          custom: newCustom,
        });
      } else if (
        name?.includes(`produto-preco-por-c-`) ||
        name?.includes(`produto-preco-2-centavos-`)
      ) {
        child?.set({
          text:
            "," +
            numberCurrency(product.preco_dois)
              .replace("R$", "")
              .replace(/\s/g, "")
              .split(",")[1],
          custom: newCustom,
        });
      } else if (name?.includes(`produto-preco-por-`)) {
        child?.set({
          text:
            typeof product?.preco_dois === "number"
              ? numberCurrency(product.preco_dois)
                  .replace("R$", "")
                  .replace(/\s/g, "")
              : " ",
          custom: newCustom,
        });
      } else if (name?.startsWith("var1")) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            product.var1 || " "
          ),
          custom: newCustom,
        });
      } else if (name?.startsWith("var2")) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            product.var2 || " "
          ),
          custom: newCustom,
        });
      } else if (name?.startsWith("var3")) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            product.var3 || " "
          ),
          custom: newCustom,
        });
      } else if (name?.startsWith("var4")) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            product.var4 || " "
          ),
          custom: newCustom,
        });
      } else if (name?.startsWith("var5")) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            product.var5 || " "
          ),
          custom: newCustom,
        });
      } else if (name?.startsWith("var6")) {
        child?.set({
          text: adjustCaseFontReturnString(
            attributes?.fontCase,
            product.var6 || " "
          ),
          custom: newCustom,
        });
      }
    });
  }
};

export const adjustLayoutChangeAttributes = (store: any, data: any) => {
  if (Boolean(store.activePage)) {
    const elements = store.activePage.children?.filter((child: any) =>
      Boolean(child?.custom?.name)
    );

    const productsActivePage = productsPerPage(store.activePage);

    store?.pages.forEach((page: any) => {
      const productsPage = productsPerPage(page);

      if (productsActivePage === productsPage) {
        page.children
          ?.reduce((children: any[], child: any) => {
            const existingItem = children.find(
              (item: any) => item?.custom?.name === child?.custom?.name
            );
            let _child = { ...child };

            if (existingItem) {
              child.set({
                custom: {
                  ...child.custom,
                  name: `${_child?.custom?.name}-${makeId(8)}`,
                },
              });
            }

            return children.concat(child);
          }, [])
          ?.forEach((child: any) => {
            const element = elements?.find(
              (element: any) => element?.custom?.name === child?.custom?.name
            );
            if (element && element?.id !== child?.id) {
              if (
                element.width !== child.width ||
                element.height !== child.height ||
                element.x !== child.x ||
                element.y !== child.y
              ) {
                store.history.ignore(() => {
                  child.set({
                    width: element.width,
                    height: element.height,
                    x: element.x,
                    y: element.y,
                  });
                });
              }
            }
          });
      }
    });
  }
};

export const adjustSizeProductImage = (page: any, size: number) => {
  const percentage = size / 100;
  page.children
    ?.filter((child: any) => child?.custom?.name?.includes("produto-imagem-"))
    .forEach((child: any) => {
      const width = child?.custom?.oldWidth || child?.width;
      const height = child?.custom?.oldHeight || child?.height;
      const custom = {
        ...child.custom,
        oldWidth: width,
        oldHeight: height,
      };

      child.set({
        width: width + width * percentage,
        height: height + height * percentage,
        custom,
      });
    });
};

export const adjustSizeProductDescription = (page: any, size: number) => {
  const percentage = size / 100;
  page.children
    ?.filter(
      (child: any) =>
        child?.custom?.name?.includes("produto-descricao-") ||
        child?.custom?.name?.includes("produto-nome-")
    )
    .forEach((child: any) => {
      const fontSize = child?.custom?.oldFontSize || child?.fontSize;
      const custom = { ...child.custom, oldFontSize: fontSize };

      child.set({
        fontSize: fontSize + fontSize * percentage,
        custom,
      });
    });
};

export const adjustFontCase = (page: any, type: string) => {
  page.children
    ?.filter((child: any) => child.type === "text")
    .forEach((child: any) =>
      child.set({
        text: Boolean(child?.text) ? adjustCaseFont(type, child?.text) : " ",
      })
    );
};

export const adjustFontFamily = (page: any, font: string, type: string) => {
  page.children
    ?.filter((child: any) => {
      switch (type.toLowerCase()) {
        case "products":
          return (
            child?.custom?.name?.includes("produto-nome-") ||
            child?.custom?.name?.includes("produto-descricao-")
          );
        case "tags":
          return (
            child?.custom?.name?.includes("produto-preco-") ||
            child?.custom?.name?.includes("produto-medida-")
          );
        case "footer":
          return (
            child?.custom?.name?.includes("endereco") ||
            child?.custom?.name?.includes("telefone") ||
            child?.custom?.name?.includes("descricao") ||
            child?.custom?.name?.startsWith("var")
          );
      }
    })
    .forEach((child: any) =>
      child.set({
        fontFamily: font,
      })
    );
};

export const adjustViewUnits = (page: any, type: string) => {
  page.children
    ?.filter(
      (child: any) =>
        child?.custom?.name?.includes("medida-") ||
        child?.custom?.name?.includes("produto-nome-") ||
        child?.custom?.name?.includes("produto-descricao-")
    )
    .forEach((child: any) => {
      const values = child?.custom?.values;
      const medida = values?.medida || findUnits(values?.nome_produto);
      const produto = unitReplace(values?.nome_produto);

      if (child?.custom?.name?.includes("medida-")) {
        if (type === "tag") {
          child.set({ text: medida });
        } else if (type === "description") {
          child.set({ text: " " });
        } else {
          child.set({ text: medida.trim() });
        }
      }

      if (
        child?.custom?.name?.includes("produto-nome-") ||
        child?.custom?.name?.includes("produto-descricao-")
      ) {
        if (type === "tag") {
          child.set({ text: produto });
        } else if (type === "description") {
          child.set({ text: `${produto} ${medida}` });
        } else {
          child.set({ text: `${produto} ${medida}` });
        }
      }
    });
};

export const calulateProductsPerPage = (
  productQuantity: number,
  productsPerPage: number,
  products: any[]
) => {
  let productsPage = [];
  let maxProducts = 0;
  let minProducts = 0;

  for (let i = 0; i < productQuantity; i += productsPerPage) {
    const perPage = products
      .map((product, index) => ({ ...product, index }))
      .slice(i, i + productsPerPage);
    productsPage.push(perPage);

    if (perPage.length > maxProducts) {
      maxProducts = perPage.length;
    }

    if (perPage.length >= minProducts && perPage.length < maxProducts) {
      minProducts = perPage.length;
    }
  }

  return { productsPage, maxProducts, minProducts };
};

export const elementsFonts = (page: any, type: string) => {
  return page.children
    ?.filter((child: any) => {
      switch (type.toLowerCase()) {
        case "products":
          return (
            child?.custom?.name?.includes("produto-nome-") ||
            child?.custom?.name?.includes("produto-descricao-")
          );
        case "tags":
          return (
            child?.custom?.name?.includes("produto-preco-") ||
            child?.custom?.name?.includes("produto-medida-")
          );
        case "footer":
          return (
            child?.custom?.name?.includes("endereco") ||
            child?.custom?.name?.includes("telefone") ||
            child?.custom?.name?.startsWith("var")
          );
      }
    })
    .flat();
};

export const productsPerPage = (page: any) => {
  return (
    page.children?.filter(
      (child: any) =>
        child?.custom?.name?.includes("produto-descricao") ||
        child?.custom?.name?.includes("produto-nome")
    )?.length || 1
  );
};

export const pad = (value: number) => {
  return value <= 9 ? `0${value}` : `${value}`;
};

export const setProduct = (
  child: any,
  product: Products,
  indexProduct: number,
  name: string,
  attributes?: any
) => {
  if (name?.includes(`produto-imagem-${pad(indexProduct)}`)) {
    const image =
      product?.imagem_produto === "error"
        ? import.meta.env.VITE_REACT_IMAGE_NOT_FOUND_PRODUCT
        : product?.imagem_produto ||
          import.meta.env.VITE_REACT_IMAGE_TRANSPARENT;
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      src: image,
    };
  } else if (name?.includes(`produto-preco-por-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.tagFontFamily)
        ? attributes?.tagFontFamily
        : child.fontFamily,
      text: Boolean(product?.preco_dois)
        ? numberCurrency(product.preco_dois)
            .replace("R$", "")
            .replace(/\s/g, "")
        : " ",
    };
  } else if (
    name?.includes(`produto-preco-por-r-${pad(indexProduct)}`) ||
    name?.includes(`produto-preco-2-reais-${pad(indexProduct)}`)
  ) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.tagFontFamily)
        ? attributes?.tagFontFamily
        : child.fontFamily,
      text: numberCurrency(product.preco_dois)
        .replace("R$", "")
        .replace(/\s/g, "")
        .split(",")[0],
    };
  } else if (
    name?.includes(`produto-preco-por-c-${pad(indexProduct)}`) ||
    name?.includes(`produto-preco-2-centavos-${pad(indexProduct)}`)
  ) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.tagFontFamily)
        ? attributes?.tagFontFamily
        : child.fontFamily,
      text:
        "," +
        numberCurrency(product.preco_dois)
          .replace("R$", "")
          .replace(/\s/g, "")
          .split(",")[1],
    };
  } else if (name?.includes(`produto-preco-de-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.tagFontFamily)
        ? attributes?.tagFontFamily
        : child.fontFamily,
      text: Boolean(product?.preco_original)
        ? numberCurrency(product.preco_original)
            .replace("R$", "")
            .replace(/\s/g, "")
        : " ",
    };
  } else if (name?.includes(`produto-medida-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.medida || " ")
      ),
      fontFamily: Boolean(attributes?.tagFontFamily)
        ? attributes?.tagFontFamily
        : child.fontFamily,
    };
  } else if (
    name?.includes(`produto-descricao-${pad(indexProduct)}`) ||
    name?.includes(`produto-nome-${pad(indexProduct)}`)
  ) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.nome_produto || " ")
      ),
    };
  } else if (name?.includes(`etiqueta-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      src: product?.tagURL || attributes?.tagURL || child?.src,
      custom: { ...child.custom, values: { ...product } },
    };
  } else if (name?.includes(`var1-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.var1 || " ")
      ),
    };
  } else if (name?.includes(`var1-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.var1 || " ")
      ),
    };
  } else if (name?.includes(`var2-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.var2 || " ")
      ),
    };
  } else if (name?.includes(`var3-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.var3 || " ")
      ),
    };
  } else if (name?.includes(`var4-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.var4 || " ")
      ),
    };
  } else if (name?.includes(`var5-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.var5 || " ")
      ),
    };
  } else if (name?.includes(`var6-${pad(indexProduct)}`)) {
    return {
      ...child,
      id: makeId(16),
      custom: { ...child.custom, values: { ...product } },
      fontFamily: Boolean(attributes?.productFontFamily)
        ? attributes?.productFontFamily
        : child.fontFamily,
      text: adjustCaseFontReturnString(
        attributes?.fontCase,
        String(product?.var6 || " ")
      ),
    };
  }
};

export const setDefaultTagProduct = (dynamic?: string, attributes?: any) => {
  switch (dynamic) {
    case "preco_unico":
      return attributes?.tagURLPrecoUnico || dynamicsTags.preco_unico;

    case "de_por":
      return attributes?.tagURLDePor || dynamicsTags.de_por;

    case "varejo_atacado":
      return attributes?.tagURLVarejAtac || dynamicsTags.varejo_atacado;

    case "pack_unitario":
      return attributes?.tagURLPackUnit || dynamicsTags.pack_unitario;

    case "normal_clube":
      return attributes?.tagURLNormalClub || dynamicsTags.normal_clube;

    case "normal_cartao":
      return attributes?.tagURLNormalCartao || dynamicsTags.normal_cartao;

    default:
      return undefined;
  }
};
