import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import api, { apiAzure } from "../services/api";
import { growthbook } from "../services/growthbook";
import {
  forgotPassword,
  refreshSession,
  signInService,
  signInServiceSmart,
} from "../services/sessions";
import { SignInCredentials } from "../types/session";
import { RequestAccountRecover, User, UserPermissions } from "../types/user";

type AuthProviderProps = {
  children: React.ReactNode;
};

export type AuthState = {
  token: string;
  user: User;
  permissions: UserPermissions;
  distributor?: { smart?: boolean; martins?: boolean };
};

export type AuthContextData = {
  distributor?: { smart?: boolean; martins?: boolean };
  token: string;
  user: User;
  loading: boolean;
  loadStorageData(): Promise<void>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInSmart(credentials: SignInCredentials): Promise<void>;
  accountRecover(account: RequestAccountRecover): Promise<void>;
  changeUser(account: any): Promise<void>;
  persistUser(
    user: User,
    permissions: UserPermissions,
    token: string,
    isWhitelabel?: boolean,
    isInAccessDsFaz?: boolean
  ): Promise<void>;
  signOut(): void;
};

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export function AuthProvider({ children }: AuthProviderProps) {
  const enabled = useFeatureIsOn("DS_VERSAO_V3");
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [loading, setLoading] = useState(true);

  const signOut = useCallback(async () => {
    setData({} as AuthState);

    localStorage.clear();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location?.search);
    console.log("ORIGIN => ", query.get("origin"));
    if (enabled && data.token && !query.get("origin")) {
      window.location.replace(
        `https://old.datasales.info/sign-as-user/${data.token}`
      );
    }
  }, [enabled, data.token, window.location?.search]);

  useEffect(() => {
    loadStorageData();
  }, [signOut]);

  const loadStorageData = async (): Promise<void> => {
    try {
      const localToken = localStorage.getItem("@datasales:token");
      const localUser = localStorage.getItem("@datasales:user");

      if (localToken && localUser) {
        const smartLocal = localStorage.getItem("@datasales:smart") === "true";
        const martinsLocal =
          localStorage.getItem("@datasales:martins") === "true";
        const localTags = localStorage.getItem("@datasales:tags");
        const parsedUser = JSON.parse(localUser);
        const response = await refreshSession({
          token: localToken,
          userId: parsedUser.id,
        });
        const { token, user, permissions } = response;

        const signature = user?.signature !== null ? user?.signature : "free";

        api.defaults.headers.common.authorization = `${token}`;
        apiAzure.defaults.headers.common.authorization = `${token}`;

        const now = new Date();
        const startingData = new Date(user.trialStartDate);
        const endingData = new Date(user.trialEndDate);

        const trialPeriodIsValid =
          user.trial && now >= startingData && now <= endingData;

        user.trialPeriodIsValid = trialPeriodIsValid;

        if (signature === "free" && trialPeriodIsValid) {
          user.signature = "enterprise";
        }

        api.defaults.headers.common.authorization = `${token}`;
        apiAzure.defaults.headers.common.authorization = `${token}`;
        localStorage.setItem(
          "@datasales:user",
          JSON.stringify({ ...parsedUser, ...user })
        );

        if (localTags) {
          const attributes = JSON.parse(localTags);
          growthbook.setAttributes(attributes);
        }

        setData({
          token,
          user,
          permissions,
          distributor: { martins: martinsLocal, smart: smartLocal },
        });
      }
    } catch (err) {
      signOut();
    } finally {
      setLoading(false);
    }
  };

  const signIn = useCallback(async ({ email, password }: any) => {
    const response = await signInService({ email, password });

    const { token, user, permissions, tags } = response;

    api.defaults.headers.common.authorization = `${token}`;
    apiAzure.defaults.headers.common.authorization = `${token}`;

    const signature = user?.signature !== null ? user?.signature : "free";

    api.defaults.headers.common.authorization = `${token}`;
    apiAzure.defaults.headers.common.authorization = `${token}`;

    const now = new Date();
    const startingData = new Date(user.trialStartDate);
    const endingData = new Date(user.trialEndDate);

    const trialPeriodIsValid =
      user.trial && now >= startingData && now <= endingData;

    user.trialPeriodIsValid = trialPeriodIsValid;

    if (signature === "free" && trialPeriodIsValid) {
      user.signature = "enterprise";
    }

    if (tags) {
      growthbook.setAttributes(tags);
      localStorage.setItem("@datasales:tags", JSON.stringify(tags));
    }

    localStorage.setItem("@datasales:token", token);
    localStorage.setItem("@datasales:user", JSON.stringify(user));
    localStorage.setItem(
      "@datasales:user:permissions",
      JSON.stringify(permissions)
    );

    setData({
      token,
      user,
      permissions,
    });
  }, []);

  const signInSmart = useCallback(async ({ email, password, host }: any) => {
    const response = await signInServiceSmart({ email, password, host });

    const { token, user, permissions } = response;

    api.defaults.headers.common.authorization = `${token}`;
    apiAzure.defaults.headers.common.authorization = `${token}`;

    localStorage.setItem("@datasales:token", token);
    localStorage.setItem("@datasales:user", JSON.stringify(user));
    localStorage.setItem(
      "@datasales:user:permissions",
      JSON.stringify(permissions)
    );
    const smartLocal = localStorage.getItem("@datasales:smart") === "true";
    const martinsLocal = localStorage.getItem("@datasales:martins") === "true";

    setData({
      token,
      user,
      permissions,
      distributor: { martins: martinsLocal, smart: smartLocal },
    });
  }, []);

  const persistUser = useCallback(
    async (
      user: User,
      permissions: UserPermissions,
      token: string,
      isWhitelabel?: boolean,
      isInAccessDsFaz?: boolean
    ) => {
      localStorage.removeItem("@datasales:tags");
      const signature = user?.signature !== null ? user?.signature : "free";

      const now = new Date();
      const startingData = new Date(user.trialStartDate);
      const endingData = new Date(user.trialEndDate);

      const trialPeriodIsValid =
        user.trial && now >= startingData && now <= endingData;

      user.trialPeriodIsValid = trialPeriodIsValid;

      if (signature === "free" && trialPeriodIsValid) {
        user.signature = "enterprise";
      }

      localStorage.setItem("@datasales:token", token);
      localStorage.setItem("@datasales:user", JSON.stringify(user));
      if (isWhitelabel || localStorage.getItem("@datasales:isWhitelabel")) {
        localStorage.setItem("@datasales:isWhitelabel", "true".toString());
      }
      if (isInAccessDsFaz) {
        api.defaults.headers.common.authorization = `${token}`;
        apiAzure.defaults.headers.common.authorization = `${token}`;
      }
      localStorage.setItem(
        "@datasales:user:permissions",
        JSON.stringify(permissions)
      );
      setData({ token, user, permissions });
    },
    []
  );

  const changeUser = useCallback(async (user: User) => {
    localStorage.setItem("@datasales:user", JSON.stringify(user));

    setData((prev) => ({ ...prev, user }));
  }, []);

  const accountRecover = useCallback(async (data: RequestAccountRecover) => {
    await forgotPassword(data);
  }, []);

  const authContextData = useMemo(() => {
    return {
      distributor: data.distributor,
      token: data.token,
      loading,
      user: data.user,
      signIn,
      signOut,
      persistUser,
      changeUser,
      signInSmart,
      accountRecover,
      loadStorageData,
    };
  }, [
    data.distributor,
    data.token,
    data.user,
    signIn,
    loading,
    signOut,
    persistUser,
    changeUser,
    signInSmart,
    accountRecover,
    loadStorageData,
  ]);

  return (
    <AuthContext.Provider value={authContextData}>
      {children}
    </AuthContext.Provider>
  );
}
