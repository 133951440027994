import React from "react";
import { styled } from "@mui/material/styles";
import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Button, { ButtonProps } from "@mui/material/Button";
import { TransitionProps } from "@mui/material/transitions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import { useTheme, useMediaQuery } from "@mui/material";

type DialogProps = MuiDialogProps & {
  title?: React.ReactNode;
  children: React.ReactNode;
  actions?: ButtonProps[];
  onClose?: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlurryDialog = styled(MuiDialog)<DialogProps>(({ theme }) => ({
  backdropFilter: "blur(2px)",
}));

const Dialog = ({
  title,
  children,
  actions,
  onClose,
  ...rest
}: DialogProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <BlurryDialog
      {...rest}
      onClose={() => {}}
      aria-labelledby="responsive-dialog-title"
      id="responsive-dialog"
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          boxShadow: "none",
          backgroundColor: "#FFF",
        },
      }}
    >
      <Stack spacing={2} px={isMobile ? 2 : 4} py={3}>
        <Fade
          in={Boolean(title)}
          unmountOnExit
          timeout={{ exit: 0, enter: 500 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            id="responsive-dialog-title"
            alignItems="center"
          >
            <Typography variant={isMobile ? "subtitle1" : "h6"}>
              {title}
            </Typography>
            <Fade
              in={Boolean(onClose)}
              unmountOnExit
              timeout={{ exit: 0, enter: 500 }}
            >
              <IconButton aria-label="close" onClick={onClose}>
                <i style={{ fontSize: 20 }} className="fi fi-rr-cross-small" />
              </IconButton>
            </Fade>
          </Stack>
        </Fade>

        <Stack id="responsive-dialog-content">{children}</Stack>
        <Fade
          in={actions && Array.isArray(actions)}
          unmountOnExit
          timeout={{ exit: 0, enter: 500 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            id="responsive-dialog-actions"
          >
            {actions?.map((action, index) => (
              <Button key={index.toString()} {...action} />
            ))}
          </Stack>
        </Fade>
      </Stack>
    </BlurryDialog>
  );
};

export default Dialog;
