import { CSSProperties } from "react";
import Stack from "@mui/material/Stack";
import gif from "../../assets/gifs/loading.gif";
import Typography from "@mui/material/Typography";

type LoaderProps = {
  styleImage?: CSSProperties;
  title?: string;
};

const Loader = ({ styleImage, title }: LoaderProps) => {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      flex={1}
      height="100%"
    >
      <img
        style={{ objectFit: "contain", width: "60%", ...styleImage }}
        alt="image-logo"
        src={gif}
      />
      {title && <Typography variant="button">{title}</Typography>}
    </Stack>
  );
};

export default Loader;
