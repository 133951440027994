import axios from "axios";
import jwt_decode from "jwt-decode";
import { addDays } from "date-fns";
import api from "./api";
import {
  RefreshCredentials,
  SignInCredentials,
  SignInResponse,
} from "../types/session";
import { RequestUpdateUser } from "../types/user";
import { isLocalhost } from "../utils/stage";

export const signInService = async ({
  email,
  password,
}: SignInCredentials): Promise<SignInResponse> => {
  const loginURL = isLocalhost
    ? "https://api-backoffice.datasales.info/p2-dev/login"
    : "https://api-backoffice.datasales.info/p2-prd/login";
  const response = await axios.post(loginURL, {
    email,
    password,
  });

  const {
    tags,
    token,
    name,
    distributorId,
    permissions,
    studioType,
    studioFontStyle,
    viewUnits,
    adjustProductsLayout,
    company: {
      id: companyId,
      name: companyName,
      cnpj: companyDocument,
      ID_SEGMENT: segmentId,
      ID_SEGMENT_STUDIO: segmentStudioId,
      trial,
      trialStartDate,
      trialEndDate,
      signature,
      deliveryVip,
    },
  } = response.data;

  const parsedToken: any = jwt_decode(token);

  return {
    tags,
    token,
    permissions,
    user: {
      distributorId,
      segmentStudioId,
      segmentId,
      companyId,
      companyName,
      companyDocument,
      name,
      deliveryVip,
      signature,
      trial,
      trialStartDate,
      trialEndDate,
      email,
      id: parsedToken.user_id as number,
      onboard: !Boolean(parsedToken.onboard) as boolean,
      studioType,
      studioFontStyle,
      viewUnits,
      adjustProductsLayout,
    },
  };
};

export const signInServiceSmart = async ({
  email,
  password,
  host,
}: SignInCredentials): Promise<SignInResponse> => {
  const response = await axios.post(
    "https://api-backoffice.datasales.info/p2-prd/sessions/smart",
    {
      email,
      password,
      host,
    }
  );

  const {
    token,
    name,
    distributorId,
    permissions,
    studioType,
    studioFontStyle,
    viewUnits,
    adjustProductsLayout,
    company: {
      id: companyId,
      name: companyName,
      cnpj: companyDocument,
      ID_SEGMENT: segmentId,
      ID_SEGMENT_STUDIO: segmentStudioId,
      signature,
      deliveryVip,
    },
  } = response.data;

  const parsedToken: any = jwt_decode(token);

  return {
    token,
    permissions,
    user: {
      distributorId,
      segmentStudioId,
      segmentId,
      companyId,
      companyName,
      companyDocument,
      name,
      deliveryVip,
      signature,
      email,
      id: parsedToken.user_id as number,
      onboard: !Boolean(parsedToken.onboard) as boolean,
      studioType,
      studioFontStyle,
      viewUnits,
      adjustProductsLayout,
    },
  };
};

export const signUpService = async ({
  company_name,
  name,
  email,
  phone_number,
  password,
  isTablet,
}: any): Promise<any> => {
  const signUpData = {
    company_name,
    name,
    email,
    password,
    phone_number,
    trialStartDate: new Date(),
    trialEndDate: isTablet ? addDays(new Date(), -1) : addDays(new Date(), 7),
    platform: isTablet ? "desktop-mob" : "desktop",
    feeds: true,
  };

  const response = await axios.post(
    `https://api-backoffice.datasales.info/p3-prd/user/trial`,
    signUpData
  );

  return response.data;
};

export const refreshSession = async ({
  token,
  userId,
}: RefreshCredentials): Promise<SignInResponse> => {
  const response = await axios.get(
    `https://kldlktujc6.execute-api.us-east-1.amazonaws.com/prd/users/${userId}`,
    {
      headers: { Authorization: token },
    }
  );

  const {
    company: {
      id: companyId,
      name: companyName,
      cnpj: companyDocument,
      ID_SEGMENT: segmentId,
      ID_SEGMENT_STUDIO: segmentStudioId,
      onboard,
      signature,
      trial,
      trialStartDate,
      trialEndDate,
    },
    user: {
      email,
      name,
      studioType,
      studioFontStyle,
      viewUnits,
      adjustProductsLayout,
      phone_number,
    },
    permissions,
  } = response.data;

  return {
    token,
    permissions,
    user: {
      segmentStudioId,
      segmentId,
      companyId,
      companyName,
      companyDocument,
      trial,
      trialStartDate,
      trialEndDate,
      name,
      signature,
      email,
      phoneNumber: phone_number,
      id: userId,
      onboard: !Boolean(onboard) as boolean,
      studioType,
      studioFontStyle,
      viewUnits,
      adjustProductsLayout,
    },
  };
};

export const forgotPassword = async ({
  email,
  redirect,
}: {
  email: string;
  redirect: string;
}) => {
  const response = await axios.post(
    "https://api-backoffice.datasales.io/p2-prd/session/forgot",
    { email, redirect }
  );

  return response.data;
};

export const resetPassword = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => {
  const response = await axios.post(
    "https://api-backoffice.datasales.io/p2-prd/session/reset",
    { token, password }
  );

  return response.data;
};

export const editCompany = async (data: RequestUpdateUser) => {
  const response = await api.put(
    "account-settings-p1-prd/crm/account-settings/company/edit",
    { name: data.companyName, cnpj: data.document }
  );

  return response.data;
};

export const editUser = async (data: RequestUpdateUser, id: number) => {
  const response = await api.put(
    `account-settings-p1-prd/crm/account-settings/company/user/edit/${id}`,
    { name: data.name }
  );

  return response.data;
};
