/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  //useEffect,
} from "react";
import { addDays, format, isValid, parse } from "date-fns";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import {
  postCampaignWhatsAppContacts,
  postCampaignFacebook,
  postCampaignInstagram,
  postCampaignInstagramCarousel,
  postCampaignWhatsAppGroups,
  postCampaignSMS,
  postCampaignSite,
  postCampaignFacebookAdsV2,
} from "../services/distribution";
import {
  ItemsDistribute,
  FacebookAdsForm,
  SmsForm,
  SocialNetworkForm,
  WhatsAppForm,
  SiteForm,
} from "../types/distribution";
import {
  getFindFacebookPage,
  getValidatePageAds,
  postAdImages,
  updateLimit,
} from "../services/facebookAds";
import { SMSShippingTypeDistributionOptions } from "../types/sms";
import { CLICK_TRACKER_SMS } from "../pages/Distribution/constants";
import { AdsMediaSavedDb } from "../types/facebookAds";

import { pdfAws } from "../utils/downloadFile";
import { useAws } from "../hooks/useAws";
import { makeId } from "../utils/stringIdGenerate";
import { useAuth } from "../hooks/useAuth";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChangeProps = { [properties: string]: any };

export type DistributionContextData = {
  message?: string;
  isPdf?: boolean;
  uploadPdf?: boolean;
  handleChangeMessage(data: string): void;
  handleChangePdf(): void;
  socialNetworks: SocialNetworkForm;
  handleChangeSocialNetwork(data: ChangeProps): void;
  whatsapp: WhatsAppForm;
  handleChangeWhatsApp(data: ChangeProps): void;
  facebookAds: FacebookAdsForm;
  handleChangeFacebookAds(data: ChangeProps): void;
  sms: SmsForm;
  site: SiteForm;
  handleChangeSms(data: ChangeProps): void;
  handleChangeSite(date: ChangeProps): void;
  distribute(
    channelsToDistribute: string[],
    itemsDistribute: ItemsDistribute[],
    folderName?: string,
    isInfluencer?: boolean
  ): void;
  handleLimit: UseMutationResult<any, unknown, any, unknown>;
  handleFacebook: UseMutationResult<any, unknown, any, unknown>;
  handleInstagramCarousel: UseMutationResult<any, unknown, any, unknown>;
  handleInstagram: UseMutationResult<any, unknown, any, unknown>;
  handleWhatsAppPrivate: UseMutationResult<any, unknown, any, unknown>;
  handleWhatsAppGroups: UseMutationResult<any, unknown, any, unknown>;
  handleSms: UseMutationResult<any, unknown, any, unknown>;
  handleSite: UseMutationResult<any, unknown, any, unknown>;
  handleAdImage: UseMutationResult<any, unknown, any, unknown>;
  handleFacebookAds: UseMutationResult<any, unknown, any, unknown>;
  handleFindFBPage: UseMutationResult<any, unknown, any, unknown>;
  handleValidadePageAds: UseMutationResult<any, unknown, any, unknown>;
  clearDistribution(): void;
};

export const DistributionContext = createContext<DistributionContextData>(
  {} as DistributionContextData
);

const formatDateTime = (date: Date) => format(date, "dd/MM/yyyy HH:mm");
const formatTime = (date: Date) => format(date, "HH:mm");
const formatDate = (date: Date) => format(date, "dd/MM/yyyy");

export function DistributionProvider({ children }: { children: ReactNode }) {
  const { uploadS3 } = useAws();
  const { user } = useAuth();
  const [initDateForm, setInitDateForm] = useState(new Date());
  const [message, setMessage] = useState<string>("");
  const [socialNetworks, setSocialNetworks] = useState<SocialNetworkForm>(
    {} as SocialNetworkForm
  );
  const [whatsapp, setWhatsApp] = useState<WhatsAppForm>({} as WhatsAppForm);
  const [facebookAds, setFacebookAds] = useState<FacebookAdsForm>(
    {} as FacebookAdsForm
  );
  const [sms, setSms] = useState<SmsForm>({} as SmsForm);
  const [site, setSite] = useState<SiteForm>({} as SiteForm);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [uploadPdf, setUploadPdf] = useState<boolean>(false);
  const handleInstagramCarousel = useMutation(postCampaignInstagramCarousel);
  const handleFacebook = useMutation(postCampaignFacebook);
  const handleInstagram = useMutation(postCampaignInstagram);
  const handleLimit = useMutation(updateLimit);
  const handleWhatsAppPrivate = useMutation(postCampaignWhatsAppContacts);
  const handleWhatsAppGroups = useMutation(postCampaignWhatsAppGroups);
  const handleSms = useMutation(postCampaignSMS);
  const handleSite = useMutation(postCampaignSite);

  const handleAdImage = useMutation(postAdImages);
  const handleFacebookAds = useMutation(postCampaignFacebookAdsV2);
  const handleFindFBPage = useMutation(getFindFacebookPage);
  const handleValidadePageAds = useMutation(getValidatePageAds);

  const clearDistribution = useCallback(() => {
    setInitDateForm(new Date());
    setSocialNetworks({} as SocialNetworkForm);
    setWhatsApp({} as WhatsAppForm);
    setFacebookAds({} as FacebookAdsForm);
    setSms({} as SmsForm);
    setSite({} as SiteForm);
    setIsPdf(false);
    handleInstagramCarousel.reset();
    handleFacebook.reset();
    handleInstagram.reset();
    handleLimit.reset();
    handleWhatsAppPrivate.reset();
    handleWhatsAppGroups.reset();
    handleSms.reset();
    handleSite.reset();
    handleAdImage.reset();
    handleFacebookAds.reset();
    handleFindFBPage.reset();
    handleValidadePageAds.reset();
  }, [
    handleAdImage,
    handleFacebook,
    handleFacebookAds,
    handleLimit,
    handleFindFBPage,
    handleInstagram,
    handleInstagramCarousel,
    handleSms,
    handleSite,
    handleValidadePageAds,
    handleWhatsAppGroups,
    handleWhatsAppPrivate,
  ]);

  const handleChangeMessage = useCallback((data: string) => {
    setMessage(data);
  }, []);

  const handleChangePdf = useCallback(() => {
    setIsPdf((prev) => !prev);
  }, []);

  const handleChangeSocialNetwork = useCallback((data: ChangeProps) => {
    setSocialNetworks((prev) => ({ ...prev, ...data }));
  }, []);

  const handleChangeWhatsApp = useCallback((data: ChangeProps) => {
    setWhatsApp((prev) => ({ ...prev, ...data }));
  }, []);

  const handleChangeFacebookAds = useCallback((data: ChangeProps) => {
    setFacebookAds((prev) => ({ ...prev, ...data }));
  }, []);

  const handleChangeSms = useCallback((data: ChangeProps) => {
    setSms((prev) => ({ ...prev, ...data }));
  }, []);

  const handleChangeSite = useCallback((data: ChangeProps) => {
    setSite((prev) => ({ ...prev, ...data }));
  }, []);

  const verifyProfileTypeSMS = async (
    selectedContactType: SMSShippingTypeDistributionOptions
  ) => {
    switch (selectedContactType.cod) {
      case "ci":
        return 0;
      case "ca":
        return 1;
      case "all":
        return 2;
      /**
       * The selectedContactType.cod could still be cop
       *
       * cop = only for numbers in copy
       *
       * But for that case, there is boolean value 'onlyInCopy' which limits sending
       * to onlyInCopy contacts and disregards the profile_type
       */
      default:
        return 0;
    }
  };

  const distribute = useCallback(
    (
      channelsToDistribute: string[],
      itemsDistribute: ItemsDistribute[],
      folderName?: string,
      isInfluencer?: boolean
    ) => {
      const offerName = folderName || "Propaganda 1"; //offerName => nome da pasta
      const offerId = itemsDistribute[0]?.offer_id
        ? itemsDistribute[0]?.offer_id
        : itemsDistribute[0]?.id_tabloide;

      channelsToDistribute.forEach(async (channel) => {
        const isCarousel = itemsDistribute.length > 1;
        if (channel === "SOCIAL_NETWORKS") {
          const formSocialNetwork = socialNetworks;

          formSocialNetwork?.dates?.forEach(async (date) => {
            const imagesToDistribute = date.images
              ? date.images.map((image) => {
                  if (image.url?.endsWith(".json")) {
                    const _image = itemsDistribute?.find(
                      (item) => item.id === image.id
                    );
                    return { ..._image, link: _image?.image_url };
                  } else {
                    return { ...image, link: image.url };
                  }
                })
              : itemsDistribute;

            const isDistributeInstagram = formSocialNetwork.platforms.find(
              (platform) => platform.value.includes("INSTAGRAM")
            );

            const isDistributeFacebook = formSocialNetwork.platforms.find(
              (platform) => platform.value.includes("FACEBOOK")
            );

            const scheduleDate = date?.scheduleDate
              ? formatDate(date.scheduleDate)
              : undefined;
            const scheduleTime = date?.scheduleTime
              ? formatTime(date.scheduleTime)
              : undefined;

            const scheduleDateTime = `${scheduleDate} ${scheduleTime}`;

            if (isCarousel && (isDistributeInstagram || isDistributeFacebook)) {
              formSocialNetwork.platforms.forEach((plataform) => {
                const imageUrlVideoLink = imagesToDistribute[0]?.link
                  ? imagesToDistribute[0]?.link
                  : imagesToDistribute[0].image_url;

                Promise.all(
                  imagesToDistribute.map((itemDistribute: ItemsDistribute) => {
                    const currentCardLink = itemDistribute?.link
                      ? itemDistribute?.link
                      : itemDistribute.image_url;
                    const objectRequest = {
                      name: "Post Instagram Story",
                      message: date.post,
                      page_id: formSocialNetwork.profile?.page_id,
                      fb_id: formSocialNetwork.profile?.fb_user_id,
                      is_midia: !!imageUrlVideoLink,
                      advertising_id: offerId,
                      image_advertisement_id: imagesToDistribute[0].id,
                      preview_url: imagesToDistribute[0].preview_url,
                      url: currentCardLink,
                      ...(plataform.value === "INSTAGRAM_STORY" && {
                        isStory: true,
                      }),
                      send_date: scheduleDateTime,
                      start_date_campaign: formatDateTime(
                        parse(scheduleDateTime, "dd/MM/yyyy HH:mm", new Date())
                      ),
                      end_date_campaign: formatDateTime(
                        addDays(
                          parse(
                            scheduleDateTime,
                            "dd/MM/yyyy HH:mm",
                            new Date()
                          ),
                          7
                        )
                      ),
                    };

                    if (plataform.value.includes("INSTAGRAM")) {
                      if (plataform.value === "INSTAGRAM_STORY") {
                        return handleInstagram.mutate(objectRequest);
                      }
                    } else if (plataform.value === "FACEBOOK") {
                      objectRequest.name = "Post Facebook";
                      return handleFacebook.mutate(objectRequest);
                    } else {
                      return;
                    }
                  })
                );
              });

              const isFeed = formSocialNetwork.platforms.find(
                (platform) => platform.value === "INSTAGRAM_FEED"
              );
              if (isDistributeInstagram && isFeed) {
                const objectRequestInstagramCarousel = {
                  name: formSocialNetwork.title,
                  message: date.post,
                  page_id: formSocialNetwork.profile?.page_id,
                  fb_id: formSocialNetwork.profile?.fb_user_id,
                  is_midia: true,
                  advertising_id: offerId,
                  preview_url: imagesToDistribute[0].preview_url,
                  urls: imagesToDistribute.map(
                    (itemDistribute: ItemsDistribute) => {
                      const currentCardLink = itemDistribute?.link
                        ? itemDistribute?.link
                        : itemDistribute.image_url;
                      return {
                        item_advertisement_id: `${itemDistribute?.id}`,
                        url: currentCardLink,
                        is_video_media: currentCardLink?.endsWith(".mp4"),
                      };
                    }
                  ),
                  send_date: scheduleDateTime,
                  start_date_campaign: formatDateTime(
                    parse(scheduleDateTime, "dd/MM/yyyy HH:mm", new Date())
                  ),
                  end_date_campaign: formatDateTime(
                    addDays(
                      parse(scheduleDateTime, "dd/MM/yyyy HH:mm", new Date()),
                      7
                    )
                  ),
                };

                handleInstagramCarousel.mutate(objectRequestInstagramCarousel);
              }
            } else {
              formSocialNetwork.platforms.forEach((plataform) => {
                const imageUrlVideoLink = imagesToDistribute[0]?.link
                  ? imagesToDistribute[0]?.link
                  : imagesToDistribute[0].image_url;
                const preview_url = imagesToDistribute[0]?.preview_url;
                const objectRequest = {
                  name: "",
                  message: date.post,
                  page_id: formSocialNetwork.profile?.page_id,
                  fb_id: formSocialNetwork.profile?.fb_user_id,
                  is_midia: !!imageUrlVideoLink,
                  advertising_id: offerId,
                  image_advertisement_id: imagesToDistribute[0].id,
                  url: imageUrlVideoLink,
                  ...(preview_url && { preview_url: preview_url }),
                  ...(plataform.value === "INSTAGRAM_STORY" && {
                    isStory: true,
                  }),
                  send_date: scheduleDateTime,
                  start_date_campaign: formatDateTime(
                    parse(scheduleDateTime, "dd/MM/yyyy HH:mm", new Date())
                  ),
                  end_date_campaign: formatDateTime(
                    addDays(
                      parse(scheduleDateTime, "dd/MM/yyyy HH:mm", new Date()),
                      7
                    )
                  ),
                };
                if (plataform.value === "FACEBOOK") {
                  objectRequest.name = "Post Facebook";
                  handleFacebook.mutate(objectRequest);
                } else if (plataform.value.includes("INSTAGRAM")) {
                  if (plataform.value === "INSTAGRAM_REELS") {
                    objectRequest.name = "Post Instagram Reels";
                  } else if (plataform.value === "INSTAGRAM_STORY") {
                    objectRequest.name = "Post Instagram Story";
                  } else if (plataform.value === "INSTAGRAM_FEED") {
                    objectRequest.name = "Post Instagram Feed";
                  }
                  handleInstagram.mutate(objectRequest);
                }
              });
            }
          });
        }
        if (channel === "WHATSAPP") {
          const formWhatsApp = whatsapp;

          formWhatsApp?.dates?.forEach(async (date: any) => {
            const selectedShippingType =
              formWhatsApp?.selectedShippingType?.value;

            const imagesToDistribute = date.images
              ? date.images.map((itemDistribute: any) => {
                  if (itemDistribute.url?.endsWith(".json")) {
                    const _image = itemsDistribute?.find(
                      (item) => item.id === itemDistribute.id
                    );
                    return {
                      ..._image,
                      image_url: _image?.image_url,
                      preview_url: _image?.image_url,
                    };
                  } else {
                    return {
                      ...itemDistribute,
                      image_url: itemDistribute.url,
                      preview_url: itemDistribute.url,
                    };
                  }
                })
              : itemsDistribute.map((itemDistribute) => ({
                  ...itemDistribute,
                  url: itemDistribute.image_url,
                }));

            const scheduleDate = formatDate(date?.scheduleDate);
            const scheduleDateEnd = formatDate(addDays(date?.scheduleDate, 7));
            const scheduleTime = formatTime(date?.scheduleTime);

            const scheduleDateTime = `${scheduleDate} ${scheduleTime}`;
            const scheduleDateTimeEnd = `${scheduleDateEnd} ${scheduleTime}`;

            if (isPdf) {
              setUploadPdf(true);
              const name = makeId(16);
              const imageToPdf = await pdfAws(imagesToDistribute, name);

              const payloadJson = {
                file: imageToPdf,
                name,
                type: "application/pdf",
                bucketName: "datasalesio-imagens",
                folderName: user.companyId.toString(),
                subFolderName: "v4/",
                extension: "pdf",
                distinctName: false,
              };

              const desingURL = await uploadS3(payloadJson);
              setUploadPdf(false);
              const objectRequestGeneral = {
                idInstancia: formWhatsApp.selectedInstance?.id,
                id_propaganda: offerId,
                nomeCampanha: offerName,
                message: date.post,
                dataInicio: scheduleDateTime,
                dataFim: scheduleDateTimeEnd,
                dataInicioCampanha: scheduleDateTime,
                dataFimCampanha: scheduleDateTimeEnd,
                whatsapp_midia: formWhatsApp.whatsapp_midia,
                //notReceiveOffers: formWhatsApp.notReceiveOffers,
              };

              const objectURLS = {
                messageType: "PDF",
                whatsapp_url: desingURL,
              };

              if (
                selectedShippingType === "contacts" ||
                selectedShippingType === "contacts_groups" ||
                selectedShippingType === "onlyInCopy" ||
                selectedShippingType === "audience"
              ) {
                const objectRequestCreateCampaign = {
                  onlyInCopy: selectedShippingType === "onlyInCopy",
                  id_propaganda_imagem: imagesToDistribute[0].id,
                  numerosList:
                    formWhatsApp.numbersInCopy &&
                    formWhatsApp.numbersInCopy
                      .split(";")
                      .map((numb) => numb.trim().replace(/\s/g, "")),
                  numero: "",
                  origin: formWhatsApp.origin,
                  pareto: formWhatsApp.pareto.id,
                  selectedCelular:
                    formWhatsApp.selectedCelular &&
                    formWhatsApp.selectedCelular.map((value) => value.value),
                  custoCampanha: 0,
                  hostTracker: formWhatsApp.hostTracker.host,
                  ...(formWhatsApp?.audience &&
                    formWhatsApp.selectedShippingType.value === "audience" && {
                      idListProspectei: formWhatsApp.audience.idListProspectei,
                    } &&
                    formWhatsApp.selectedShippingType.value === "audience" && {
                      tipo_envio_campanha: 2.1,
                    }),
                  ...objectURLS,
                  ...objectRequestGeneral,
                };

                handleWhatsAppPrivate.mutate(objectRequestCreateCampaign);
              }
              if (
                (selectedShippingType === "onlyGroups" ||
                  selectedShippingType === "contacts_groups") &&
                formWhatsApp.groupsToSend.length
              ) {
                formWhatsApp.groupsToSend.forEach((group) => {
                  const objectRequestGroups = {
                    groupId: group.ID,
                    ...objectURLS,
                    ...objectRequestGeneral,
                  };

                  handleWhatsAppGroups.mutate(objectRequestGroups);
                });
              }
            } else {
              if (imagesToDistribute.length >= 1) {
                const objectRequestGeneral = {
                  idInstancia: formWhatsApp.selectedInstance?.id,
                  id_propaganda: offerId,
                  nomeCampanha: offerName,
                  message: date.post,
                  dataInicio: scheduleDateTime,
                  dataFim: scheduleDateTimeEnd,
                  dataInicioCampanha: scheduleDateTime,
                  dataFimCampanha: scheduleDateTimeEnd,
                  whatsapp_midia: formWhatsApp.whatsapp_midia,
                  //notReceiveOffers: formWhatsApp.notReceiveOffers,
                };

                imagesToDistribute.forEach((itemDistribute: any) => {
                  const isVideo = Boolean(
                    itemDistribute?.link?.endsWith(".mp4") ||
                      itemDistribute?.image_url?.endsWith(".mp4")
                  );
                  const isPDF = Boolean(
                    itemDistribute?.image_url?.endsWith(".pdf")
                  );
                  const objectURLS = {
                    ...(isVideo && { messageType: "VIDEO" }),
                    ...(isPDF && { messageType: "PDF" }),
                    ...(itemDistribute?.preview_url && {
                      preview_url: itemDistribute?.preview_url,
                    }),
                    whatsapp_url: isVideo
                      ? itemDistribute.link || itemDistribute.image_url
                      : itemDistribute.image_url,
                  };

                  if (
                    selectedShippingType === "contacts" ||
                    selectedShippingType === "contacts_groups" ||
                    selectedShippingType === "onlyInCopy" ||
                    selectedShippingType === "audience"
                  ) {
                    const objectRequestCreateCampaign = {
                      onlyInCopy: selectedShippingType === "onlyInCopy",
                      id_propaganda_imagem: itemDistribute.id,
                      numerosList:
                        formWhatsApp.numbersInCopy &&
                        formWhatsApp.numbersInCopy
                          .split(";")
                          .map((numb) => numb.trim().replace(/\s/g, "")),
                      numero: "",
                      origin: formWhatsApp.origin,
                      pareto: formWhatsApp.pareto.id,
                      selectedCelular:
                        formWhatsApp.selectedCelular &&
                        formWhatsApp.selectedCelular.map(
                          (value) => value.value
                        ),
                      custoCampanha: 0,
                      hostTracker: formWhatsApp.hostTracker.host,
                      ...(formWhatsApp?.audience &&
                        formWhatsApp.selectedShippingType.value ===
                          "audience" && {
                          idListProspectei:
                            formWhatsApp.audience.idListProspectei,
                        } &&
                        formWhatsApp.selectedShippingType.value ===
                          "audience" && {
                          tipo_envio_campanha: 2.1,
                        }),
                      ...objectURLS,
                      ...objectRequestGeneral,
                    };

                    handleWhatsAppPrivate.mutate(objectRequestCreateCampaign);
                  }
                  if (
                    (selectedShippingType === "onlyGroups" ||
                      selectedShippingType === "contacts_groups") &&
                    formWhatsApp.groupsToSend.length
                  ) {
                    formWhatsApp.groupsToSend.forEach((group) => {
                      const objectRequestGroups = {
                        groupId: group.ID,
                        ...objectURLS,
                        ...objectRequestGeneral,
                      };

                      handleWhatsAppGroups.mutate(objectRequestGroups);
                    });
                  }
                });
              } else {
                const objectRequestGeneral = {
                  idInstancia: formWhatsApp.selectedInstance?.id,
                  nomeCampanha: offerName,
                  message: date.post,
                  dataInicio: scheduleDateTime,
                  dataFim: scheduleDateTimeEnd,
                  dataInicioCampanha: scheduleDateTime,
                  dataFimCampanha: scheduleDateTimeEnd,
                  tipo_envio_campanha: null,
                  whatsapp_midia: false,
                  whatsapp_url: "",
                };

                if (
                  selectedShippingType === "contacts" ||
                  selectedShippingType === "contacts_groups" ||
                  selectedShippingType === "onlyInCopy" ||
                  selectedShippingType === "audience"
                ) {
                  const objectRequestCreateCampaign = {
                    onlyInCopy: selectedShippingType === "onlyInCopy",
                    numerosList:
                      formWhatsApp.numbersInCopy &&
                      formWhatsApp.numbersInCopy
                        .split(";")
                        .map((numb) => numb.trim().replace(/\s/g, "")),
                    numero: "",
                    origin: formWhatsApp.origin,
                    pareto: formWhatsApp.pareto.id,
                    selectedCelular:
                      formWhatsApp.selectedCelular &&
                      formWhatsApp.selectedCelular.map((value) => value.value),
                    custoCampanha: 0,
                    hostTracker: formWhatsApp.hostTracker.host,
                    ...(formWhatsApp?.audience &&
                      formWhatsApp.selectedShippingType.value ===
                        "audience" && {
                        idListProspectei:
                          formWhatsApp.audience.idListProspectei,
                      } &&
                      formWhatsApp.selectedShippingType.value ===
                        "audience" && {
                        tipo_envio_campanha: 2.1,
                      }),
                    ...objectRequestGeneral,
                  };

                  handleWhatsAppPrivate.mutate(objectRequestCreateCampaign);
                }
                if (
                  (selectedShippingType === "onlyGroups" ||
                    selectedShippingType === "contacts_groups") &&
                  formWhatsApp.groupsToSend.length
                ) {
                  formWhatsApp.groupsToSend.forEach((group) => {
                    const objectRequestGroups = {
                      groupId: group.ID,
                      ...objectRequestGeneral,
                    };

                    handleWhatsAppGroups.mutate(objectRequestGroups);
                  });
                }
              }
            }
          });
        }
        if (channel === "SMS") {
          const formSMS = sms;
          const onlyInCopy = formSMS.selectedContactType.cod === "cop";
          const numbersCopy = formSMS.numbersInCopy
            .split(";")
            .map((numb) => numb.trim().replace(/\s/g, ""));
          const profile_type = verifyProfileTypeSMS(
            formSMS.selectedContactType
          );
          const credits = sms.credits * 1000;
          itemsDistribute.forEach((itemDistribute) => {
            const isVideo = Boolean(itemDistribute?.link?.endsWith(".mp4"));
            const url = isVideo
              ? itemDistribute.link
              : itemDistribute.image_url;
            const objectRequestSms = {
              idInstancia: formSMS.selectedInstance?.id,
              nomeCampanha: offerName,
              message: message + CLICK_TRACKER_SMS,
              dataInicio: formatDateTime(formSMS.scheduleDate),
              ...(itemDistribute.preview_url && {
                preview_url: itemDistribute.preview_url,
              }),
              whatsapp_midia: true,
              whatsapp_url: url,
              id_propaganda: offerId,
              id_propaganda_imagem: itemDistribute.id,
              custoCampanha: credits,
              totalLimit: credits, // não é mais usado no backend
              redirectTracker: url,
              origin: "Estúdio",
              profile_type: profile_type,
              onlyInCopy: onlyInCopy,
              numbersCopy: numbersCopy,
            };
            handleSms.mutate(objectRequestSms);
          });
        }

        if (channel === "SITE") {
          const formSite = site;

          const items = itemsDistribute
            .filter(
              (item) =>
                item.image_url?.endsWith(".png") ||
                item.image_url?.endsWith(".jpeg") ||
                item.image_url?.endsWith(".jpg")
            )
            .map((item) => ({
              id: makeId(12),
              image: item.image_url,
              title: formSite.title,
              description: formSite.description,
              href: formSite.url,
              startDate:
                formSite.startDate && isValid(formSite.startDate)
                  ? formatDate(formSite.startDate)
                  : undefined,
              endDate:
                formSite.endDate && isValid(formSite.endDate)
                  ? formatDate(formSite.endDate)
                  : undefined,
            }));
          const objectRequestSite = {
            items,
            path: formSite.selectedSite?.path,
            siteId: formSite.selectedSite?.id,
          };
          handleSite.mutate(objectRequestSite);
        }

        if (channel === "FACEBOOK_ADS") {
          const {
            titleCampaing,
            objective,
            optimization_goal,
            age_min,
            age_max,
            location,
            gender,
            public_config,
            targeting,
            titleCreative,
            descriptionCreative,
            link,
            dates,
            clientFacebookPage,
          } = facebookAds;

          dates?.forEach(async (date) => {
            const imagesToDistribute = date.images
              ? date.images.map((image) => {
                  if (image.url?.endsWith(".json")) {
                    const _image = itemsDistribute?.find(
                      (item) => item.id === image.id
                    );
                    return { ..._image, image_url: _image?.image_url };
                  } else {
                    return { ...image, image_url: image.url };
                  }
                })
              : itemsDistribute;

            const ads_media: AdsMediaSavedDb[] = [];
            imagesToDistribute.forEach((item) => {
              const isVideo = Boolean(item?.url?.endsWith(".mp4"));
              ads_media.push({
                id: item.id,
                isVideo: isVideo,
                url: item?.url || item.image_url,
                ...(isVideo
                  ? { preview: item.image_url }
                  : { preview: item.preview_url }),
              });
            });

            const adsManagerDataV2 = {
              offer_id: offerId,
              version: "v4",
              campaign_data: {
                ads_media: ads_media,
                titleCampaing: titleCampaing,
                objective: objective,
                optimization_goal: optimization_goal,
                clientFacebookPage: clientFacebookPage,
                lifetime_budget: date.lifetime_budget,
                age_min: age_min,
                age_max: age_max,
                location: location,
                start_time: date.start_time,
                end_time: date.end_time,
                gender: gender,
                public_config: public_config,
                targeting: targeting,
                titleCreative: titleCreative,
                descriptionCreative: descriptionCreative,
                principalText: date.text,
                linkCreative: link,
              },
            };

            handleFacebookAds.mutate(adsManagerDataV2);

            if (isInfluencer) {
              handleLimit.mutate();
            }
          });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      message,
      socialNetworks,
      initDateForm,
      handleInstagramCarousel,
      handleFacebook,
      handleInstagram,
      whatsapp,
      sms,
      site,
      facebookAds,
    ]
  );

  const DistributionContextData = useMemo(() => {
    return {
      message,
      handleChangeMessage,
      isPdf,
      uploadPdf,
      handleChangePdf,
      socialNetworks,
      handleChangeSocialNetwork,
      whatsapp,
      handleChangeWhatsApp,
      facebookAds,
      handleChangeFacebookAds,
      sms,
      site,
      handleChangeSms,
      handleChangeSite,
      handleFacebook,
      handleInstagramCarousel,
      handleInstagram,
      handleWhatsAppPrivate,
      handleWhatsAppGroups,
      handleSms,
      handleSite,
      handleLimit,
      handleAdImage,
      handleFacebookAds,
      handleFindFBPage,
      handleValidadePageAds,
      distribute,
      clearDistribution,
    };
  }, [
    message,
    handleChangeMessage,
    isPdf,
    uploadPdf,
    handleChangePdf,
    socialNetworks,
    handleChangeSocialNetwork,
    whatsapp,
    handleChangeWhatsApp,
    facebookAds,
    handleChangeFacebookAds,
    sms,
    site,
    handleChangeSms,
    handleChangeSite,
    handleFacebook,
    handleLimit,
    handleInstagramCarousel,
    handleInstagram,
    handleWhatsAppPrivate,
    handleWhatsAppGroups,
    handleSms,
    handleSite,
    handleAdImage,
    handleFacebookAds,
    handleFindFBPage,
    handleValidadePageAds,
    distribute,
    clearDistribution,
  ]);

  return (
    <DistributionContext.Provider value={DistributionContextData}>
      {children}
    </DistributionContext.Provider>
  );
}
