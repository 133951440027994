import {
  LastLimitHistory,
  ParamAdImage,
  PlaceLocation,
  RespAdImage,
  RespFindFacebookPage,
  RespValidadePageAds,
  TargetSearch,
  Ads,
  clientFacebookPageAdsV2,
  RespFindOneFacebookAdsV2,
  RespFindAllFacebookAdsV2,
  UpdateStatusFacebookAdsV2,
} from "../types/facebookAds";
import stage from "../utils/stage";
import api from "./api";

export const getPlaceLocationDetail = async (
  placeLocation: PlaceLocation,
  index: number
): Promise<any> => {
  const response = await api.get(`/ads-manager-${stage}/places/details`, {
    params: { place_id: placeLocation?.place_id },
  });
  if (response.data?.content) {
    return { ...response.data?.content, index };
  } else {
    return { index };
  }
};

export const getLastLimitHistory = async (
  id_type = 1
): Promise<LastLimitHistory> => {
  const response = await api.get(
    `/ads-manager-${stage}/lastLimitHistory/?id_type=${id_type}`
  );
  return response.data?.content;
};

export const getTargetingSearch = async (
  targetQuery: string
): Promise<TargetSearch[]> => {
  const response = await api.get(`/ads-manager-${stage}/targeting-search`, {
    params: { q: targetQuery },
  });
  return response.data?.content;
};

export const getTargetingSearchV2 = async (
  query: string,
  type = "all"
): Promise<TargetSearch[]> => {
  const response = await api.get(
    `/ads-manager-${stage}/facebook/v2/ads/targeting-search`,
    {
      params: { query, type },
    }
  );
  return response.data?.content;
};

export const listAds = async (): Promise<Ads[]> => {
  const params = {};
  const response = await api.get(`/ads-manager-${stage}/ads`, {
    params,
  });
  return response.data?.content;
};

export const findAllFacebookAdsV2 = async ({
  startDate,
  endDate,
  page = 0,
}: {
  startDate: string;
  endDate: string;
  page: number;
}): Promise<RespFindAllFacebookAdsV2> => {
  const response = await api.get(
    `/ads-manager-${stage}/facebook/v2/ads/find-all`,
    {
      params: {
        data_inicio: startDate,
        data_fim: endDate,
        page: page,
      },
    }
  );
  return response.data?.content;
};

export const findOneFacebookAdsV2 = async (
  facebook_adset_id: string,
  facebook_ads_id: string
): Promise<RespFindOneFacebookAdsV2> => {
  const response = await api.get(
    `/ads-manager-${stage}/facebook/v2/ads/find-one/${facebook_adset_id}/${facebook_ads_id}`
  );

  return response.data?.content;
};

export const updateStatusAds = async ({
  facebook_adset_id,
  facebook_ads_id,
  status,
}: UpdateStatusFacebookAdsV2) => {
  return api.put(
    `/ads-manager-${stage}/facebook/v2/ads/status/${facebook_adset_id}/${facebook_ads_id}`,
    { status }
  );
};

export const postAdImages = async (
  data: ParamAdImage
): Promise<RespAdImage> => {
  const response = await api.post(`/ads-manager-${stage}/adimages`, data);
  return response.data?.content;
};

export const updateLimit = async (): Promise<RespAdImage> => {
  const response = await api.get(`/ads-manager-${stage}/limitInfluencer`);
  return response.data?.content;
};

export const getFindFacebookPage = async (): Promise<RespFindFacebookPage> => {
  const response = await api.get(`/ads-manager-${stage}/ads/findfacebookpage`);
  return response.data?.content;
};

export const getClientPagesFacebookAds = async (): Promise<{
  pages: clientFacebookPageAdsV2[];
}> => {
  const response = await api.get(
    `/ads-manager-${stage}/facebook/v2/ads/client-pages`
  );
  return response.data?.content;
};

export const getValidatePageAds = async (
  pageAdsCompany: string
): Promise<RespValidadePageAds> => {
  const response = await api.get(
    `/social-networks-v2-${stage}/pages/validatepageads/${pageAdsCompany}`
  );
  return response.data?.content;
};
