import jsPDF from "jspdf";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { base64ToBlobUrl, dimensionsToPdf, isHttpUrl } from "./convertFile";

const pixelsToMillimeters = (pixels: number, dpi = 72) => {
  const inches = pixels / dpi; // Converte pixels para polegadas
  const millimeters = inches * 25.4; // Converte polegadas para milímetros
  return millimeters;
};

const addImageWithDPI = (
  doc: jsPDF,
  imageData: string,
  x: number,
  y: number,
  widthMm: number,
  heightMm: number,
  imageWidthPx: number,
  imageHeightPx: number
): void => {
  const pageWidthMm = doc.internal.pageSize.getWidth();
  const pageHeightMm = doc.internal.pageSize.getHeight();

  // Calculando a escala da imagem
  const widthRatio = pageWidthMm / imageWidthPx;
  const heightRatio = pageHeightMm / imageHeightPx;
  const pixelToMmRatio = Math.min(widthRatio, heightRatio);

  // Calculando as dimensões em mm usando o ratio
  const width = imageWidthPx * pixelToMmRatio;
  const height = imageHeightPx * pixelToMmRatio;

  // Adicionando a imagem ao PDF
  doc.addImage(imageData, "JPEG", x, y, widthMm || width, heightMm || height);
};

export const pdf = async (
  images: { url: any }[],
  name: string,
  updateProgress?: (progress: number) => void
) => {
  const requestDimensions = images
    .filter((image) => !image.url?.endsWith(".mp4"))
    .map((image) => dimensionsToPdf(image.url!));

  const responseDimensions = await Promise.all(requestDimensions);

  const imageSample = responseDimensions[0];
  const widthMm = pixelsToMillimeters(imageSample.width);
  const heightMm = pixelsToMillimeters(imageSample.height);
  const doc = new jsPDF({
    orientation: widthMm > heightMm ? "landscape" : "portrait",
    unit: "mm",
    format: [widthMm, heightMm],
  });

  const totalPages = responseDimensions.length;
  let currentPage = 0;

  responseDimensions.forEach((image, index) => {
    addImageWithDPI(
      doc,
      image.base64,
      0,
      0,
      widthMm,
      heightMm,
      imageSample.width,
      imageSample.height
    );
    // doc.addImage(image.base64, "JPEG", 0, 0, width, height);
    if (index < responseDimensions.length - 1) {
      doc.addPage();
    }
    currentPage++;

    if (updateProgress) {
      const progress = Math.round((currentPage / totalPages) * 100);
      updateProgress(progress);
    }
  });

  doc.save(name);
};

export const pdfAws = async (
  images: { url: any }[],
  name: string,
  updateProgress?: (progress: number) => void
) => {
  const requestDimensions = images
    .filter((image) => !image.url?.endsWith(".mp4"))
    .map((image) => dimensionsToPdf(image.url!));

  const responseDimensions = await Promise.all(requestDimensions);
  const imageSample = responseDimensions[0];

  const doc = new jsPDF({
    orientation:
      imageSample.width > imageSample.height ? "landscape" : "portrait",
    unit: "mm",
    format: [imageSample.width, imageSample.height],
  });

  const totalPages = responseDimensions.length;
  let currentPage = 0;

  responseDimensions.forEach((image, index) => {
    doc.addImage(
      image.base64,
      "JPEG",
      0,
      0,
      imageSample.width,
      imageSample.height
    );
    if (index < responseDimensions.length - 1) {
      doc.addPage();
    }

    currentPage++;

    if (updateProgress) {
      const progress = Math.round((currentPage / totalPages) * 100);
      updateProgress(progress);
    }
  });

  const pdfData = doc.output("arraybuffer");
  const pdfBlob = new File([pdfData], name, { type: "application/pdf" });

  return pdfBlob;
};

export const printPdf = async (
  images: { url: any }[],
  name: string,
  updateProgress?: (progress: number) => void
) => {
  const requestDimensions = images
    .filter((image) => !image.url?.endsWith(".mp4"))
    .map((image) => dimensionsToPdf(image.url!));
  const responseDimensions = await Promise.all(requestDimensions);
  const imageSample = responseDimensions[0];
  const defaultCropSize = 10; //in milimeter
  const defaultDimension = {
    width: imageSample.width + 20,
    height: imageSample.height + 20,
  };
  const trimArea = {
    cornerTopLeft: {
      axisX: {
        positionX: defaultCropSize,
        positionY: defaultCropSize * 2,
        radiusX: defaultCropSize,
        radiusY: 0,
      },
      axisY: {
        positionX: defaultCropSize * 2,
        positionY: defaultCropSize,
        radiusX: 0,
        radiusY: defaultCropSize,
      },
    },
    cornerBottomLeft: {
      axisX: {
        positionX: defaultCropSize,
        positionY: defaultDimension.height - defaultCropSize * 2,
        radiusX: defaultCropSize,
        radiusY: 0,
      },
      axisY: {
        positionX: defaultCropSize * 2,
        positionY: defaultDimension.height - defaultCropSize,
        radiusX: 0,
        radiusY: defaultCropSize,
      },
    },
    cornerTopRight: {
      axisX: {
        positionX: defaultDimension.width - defaultCropSize,
        positionY: defaultCropSize * 2,
        radiusX: defaultCropSize,
        radiusY: 0,
      },
      axisY: {
        positionX: defaultDimension.width - defaultCropSize * 2,
        positionY: defaultCropSize,
        radiusX: 0,
        radiusY: defaultCropSize,
      },
    },
    cornerBottomRight: {
      axisX: {
        positionX: defaultDimension.width - defaultCropSize,
        positionY: defaultDimension.height - defaultCropSize * 2,
        radiusX: defaultCropSize,
        radiusY: 0,
      },
      axisY: {
        positionX: defaultDimension.width - defaultCropSize * 2,
        positionY: defaultDimension.height - defaultCropSize,
        radiusX: 0,
        radiusY: defaultCropSize,
      },
    },
  };

  const doc = new jsPDF({
    orientation:
      defaultDimension.width > defaultDimension.height
        ? "landscape"
        : "portrait",
    unit: "mm",
    format: [defaultDimension.width, defaultDimension.height],
  });

  const { cornerTopLeft, cornerBottomLeft, cornerTopRight, cornerBottomRight } =
    trimArea;

  const totalPages = responseDimensions.length;
  let currentPage = 0;

  responseDimensions.forEach((image, index) => {
    doc.addImage(
      image.base64,
      "JPEG",
      10,
      10,
      defaultDimension.width,
      defaultDimension.height
    );
    doc.setFillColor(0.4, 0.3, 0.2, 1.0);
    doc.setDrawColor(0.4, 0.3, 0.2, 1.0);
    doc.setTextColor(0.4, 0.3, 0.2, 1.0);
    //corner top left
    doc.ellipse(
      cornerTopLeft.axisX.positionX,
      cornerTopLeft.axisX.positionY,
      cornerTopLeft.axisX.radiusX,
      cornerTopLeft.axisX.radiusY
    );
    doc.ellipse(
      cornerTopLeft.axisY.positionX,
      cornerTopLeft.axisY.positionY,
      cornerTopLeft.axisY.radiusX,
      cornerTopLeft.axisY.radiusY
    );
    //corner bottom left
    doc.ellipse(
      cornerBottomLeft.axisX.positionX,
      cornerBottomLeft.axisX.positionY,
      cornerBottomLeft.axisX.radiusX,
      cornerBottomLeft.axisX.radiusY
    );
    doc.ellipse(
      cornerBottomLeft.axisY.positionX,
      cornerBottomLeft.axisY.positionY,
      cornerBottomLeft.axisY.radiusX,
      cornerBottomLeft.axisY.radiusY
    );

    //corner top right
    doc.ellipse(
      cornerTopRight.axisX.positionX,
      cornerTopRight.axisX.positionY,
      cornerTopRight.axisX.radiusX,
      cornerTopRight.axisX.radiusY
    );
    doc.ellipse(
      cornerTopRight.axisY.positionX,
      cornerTopRight.axisY.positionY,
      cornerTopRight.axisY.radiusX,
      cornerTopRight.axisY.radiusY
    );

    //corner bottom right
    doc.ellipse(
      cornerBottomRight.axisX.positionX,
      cornerBottomRight.axisX.positionY,
      cornerBottomRight.axisX.radiusX,
      cornerBottomRight.axisX.radiusY
    );
    doc.ellipse(
      cornerBottomRight.axisY.positionX,
      cornerBottomRight.axisY.positionY,
      cornerBottomRight.axisY.radiusX,
      cornerBottomRight.axisY.radiusY
    );

    if (index < responseDimensions.length - 1) {
      doc.addPage();
    }

    currentPage++;

    if (updateProgress) {
      const progress = Math.round((currentPage / totalPages) * 100);
      updateProgress(progress);
    }
  });

  doc.save(name);
};

export const zip = async (
  images: { url: any }[],
  name: string,
  updateProgress?: (progress: number) => void
) => {
  const arts = images.map((image, index) => ({
    name: Boolean(image.url?.type)
      ? `${index + 1}.${image.url?.type.split("/").pop()}`
      : `${index}.jpg`,
    base64: image.url,
  }));
  const jszip = new JSZip();
  JSZip.support.nodebuffer = false;
  const folder = jszip.folder("collection");

  const totalPages = arts.length;
  let currentPage = 0;

  if (folder) {
    arts.forEach((image) => {
      folder.file(image.name, image.base64!, {
        base64: !isHttpUrl(image.base64!),
      });

      currentPage++;

      if (updateProgress) {
        const progress = Math.round((currentPage / totalPages) * 100);
        updateProgress(progress);
      }
    });

    const response = await folder.generateAsync({ type: "blob" });
    if (response) {
      saveAs(response, name);
    }
  }
};

export const file = (
  images: { url: string }[],
  type: string = "post",
  contentType: string = "image/jpeg"
) => {
  images.forEach((image, index) => {
    const url = base64ToBlobUrl(image.url!, contentType);

    const anchorElement = document.createElement("a");
    anchorElement.href = url;
    anchorElement.download = `${type}-${index + 1}.jpg`;
    anchorElement.style.display = "none";

    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);

    URL.revokeObjectURL(url);
  });
};

export const url = async (url: string, name: string) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: { "Cache-Control": "no-cache" },
    });
    const fileBlob = await response.blob();

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(fileBlob);
    downloadLink.download = `${name}.${fileBlob.type.split("/").pop()}`;

    downloadLink.click();

    URL.revokeObjectURL(downloadLink.href);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
