const typesArts = [
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/1.png",
    title: "Post para redes sociais",
    slug: "post",
    formatId: 1,
    subtitle: "Formato quadrado, ideal para redes sociais",
    // description: "Até 8 produtos",
    isVideo: false,
    isWhitelabel: true,
    height: 1200,
    width: 1200,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/2.png",
    title: "Encarte",
    formatId: 2,
    slug: "encarte",
    subtitle: "Ideal para panfletos e artes com muitos produtos",
    // description: "Até 30 produtos",
    isVideo: false,
    isWhitelabel: true,
    height: 3508,
    width: 2480,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/3.png",
    title: "Cartaz retrato",
    formatId: 14,
    slug: "cartaz-retrato",
    subtitle: "Ideal para espaços físicos",
    // description: "Até 8 produtos",
    isVideo: false,
    isWhitelabel: true,
    height: 3508,
    width: 2480,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/4.png",
    title: "Cartaz paisagem",
    slug: "cartaz-paisagem",
    formatId: 15,
    subtitle: "Ideal para espaços físicos",
    // description: "Até 8 produtos",
    isVideo: false,
    isWhitelabel: true,
    height: 2480,
    width: 3508,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/5.png",
    title: "Story",
    slug: "story",
    formatId: 3,
    subtitle: "Ideal para Stories e Status do WhatsApp",
    // description: "Até 8 produtos",
    isVideo: false,
    isWhitelabel: true,
    height: 1920,
    width: 1080,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/6.png",
    title: "TV",
    formatId: 5,
    slug: "tv",
    subtitle: "Salve em um Pen drive e promocione na sua televisão",
    // description: "Até 8 produtos",
    isVideo: false,
    isWhitelabel: true,
    height: 1080,
    width: 1920,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/7.png",
    title: "Etiquetas",
    slug: "etiqueta",
    formatId: 16,
    subtitle: "Ideal para colocar nas etiquetas de gôndolas",
    // description: "Até 8 produtos",
    isVideo: false,
    isWhitelabel: true,
    height: 3508,
    width: 2480,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/12.png",
    title: "Banner",
    slug: "personalizado-banner",
    subtitle: "Crie um Banner para a sua homepage",
    formatId: 35,
    isVideo: false,
    isWhitelabel: true,
    noV4: true,
    height: 400,
    width: 1177,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/8.png",
    title: "Modelos prontos",
    slug: "modelos-prontos",
    subtitle: "Modelos com fundos mais elaborados",
    // description: "Até 1 produto por imagem",
    isVideo: false,
    isWhitelabel: false,
    height: 1200,
    width: 1200,
  },
  {
    video:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/9.mp4",
    title: "Story",
    slug: "story-video",
    formatId: 3,
    subtitle: "Formato quadrado, ideal para redes sociais",
    // description: "Até 8 produtos",
    isVideo: true,
    isWhitelabel: false,
  },
  {
    video:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/10.mp4",
    title: "Post",
    slug: "post-video",
    formatId: 1,
    subtitle: "Formato quadrado, ideal para redes sociais",
    // description: "Até 8 produtos",
    isVideo: true,
    isWhitelabel: false,
  },
  // {
  //   image:
  //     "https://storage.googleapis.com/datasalesio-imagens/assets-ds-marketing-web/images/canva.png",
  //   title: "Canva",
  //   slug: "canva",
  //   subtitle: "Crie suas artes no Canva e distribua pela ds.marketing",
  //   isVideo: false,
  //   noNavigate: true,
  //   isWhitelabel: false,
  // },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/11.png",
    title: "Importar",
    slug: "importar",
    subtitle: "Faça upload de uma imagem ou vídeo",
    isVideo: false,
    noNavigate: true,
    isWhitelabel: false,
  },
  {
    image:
      "https://datasalesio-imagens.s3.amazonaws.com/assets-ds-marketing-web/12.png",
    title: "Personalizado",
    slug: "personalizado",
    subtitle: "Crie um modelo em branco",
    isVideo: false,
    noNavigate: true,
    isWhitelabel: false,
  },
];

export default typesArts;
