import React, { createContext, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";

type SiteProviderProps = {
  children: React.ReactNode;
};

type Color = {
  bgcolor: string;
  primary: string;
  secondary: string;
  label: string;
  id: number;
};

type SiteState = {
  general: any;
  store: any;
  catalog: any;
  description: any;
  links: any;
  address: any;
  footer: any;
};

type ChangeSiteProps = { [properties: string]: any };

export type SiteContextData = {
  site: SiteState;
  colors: Color[];
  handleChangeSite(key: string, data: ChangeSiteProps): void;
  resetSite(): void;
  eventLoadIframe(designURL: string): void;
};

export const SiteContext = createContext<SiteContextData>(
  {} as SiteContextData
);

export function SiteProvider({ children }: SiteProviderProps) {
  const iframe = document.getElementById("template-site");

  const colors = [
    {
      id: 1,
      label: "Aurora Pastel",
      bgcolor: "#310D20",
      primary: "#F0803C",
      secondary: "#310D20",
    },

    {
      id: 2,
      bgcolor: "#D8C99B",
      primary: "#A4243B",
      secondary: "#D8973C",
      label: "Sereno Marítimo",
    },

    {
      id: 3,
      bgcolor: "#456990",
      primary: "#E4FDE1",
      secondary: "#114B5F",
      label: "Crepúsculo Dourado",
    },

    {
      id: 4,
      bgcolor: "#D72638",
      primary: "#FAFAFA",
      secondary: "#D72638",
      label: "Floresta Encantada",
    },

    {
      id: 5,
      bgcolor: "#E65F5C",
      primary: "#F5F7DC",
      secondary: "#E65F5C",
      label: "Aurora Boreal",
    },

    {
      id: 6,
      bgcolor: "#FF7E6B",
      primary: "#FFF7F8",
      secondary: "#FF7E6B",
      label: "Sonho de Verão",
    },

    {
      id: 7,
      bgcolor: "#D62246",
      primary: "#D4F4DD",
      secondary: "#D62246",
      label: "Alvorada Rosada",
    },
  ];

  const [site, setSite] = useState<SiteState>({
    general: { active: true },
    store: {},
    catalog: {},
    description: {},
    links: {},
    address: {},
    footer: {},
  } as SiteState);

  const eventLoadIframe = (json: any) => {
    setSite({ ...json });
  };

  const handleChangeSite = (key: string, data: ChangeSiteProps) => {
    setSite((prevState) => {
      //@ts-ignore
      const payload = { ...prevState, [key]: { ...prevState[key], ...data } };

      //@ts-ignore
      iframe?.contentWindow?.postMessage(JSON.stringify(payload), "*");

      return payload;
    });
  };

  const resetSite = () => {
    setSite({
      general: { active: true },
      store: {},
      catalog: {},
      description: {},
      links: {},
      address: {},
      footer: {},
    } as SiteState);
  };

  const SiteContextData = useMemo(() => {
    return {
      site,
      colors,

      handleChangeSite,
      resetSite,
      eventLoadIframe,
    };
  }, [site, colors, handleChangeSite, resetSite, eventLoadIframe]);

  return (
    <SiteContext.Provider value={SiteContextData}>
      {children}
    </SiteContext.Provider>
  );
}
