import { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { QueryClientProvider } from "@tanstack/react-query";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { PickersLocaleText } from "@mui/x-date-pickers/locales";
import { configureDesignSystem } from "../theme/designTheme";
import { queryClient } from "../services/queryClient";
import { growthbook } from "../services/growthbook";
import { AuthProvider } from "./Auth";
import { ToastProvider } from "./Toast";
import { DateProvider } from "./Date";
import { StudioProvider } from "./Studio";
import { DialogConfirmationProvider } from "./DialogConfirmation";
import { AwsProvider } from "./Aws";
import { AzureStorageProvider } from "./AzureStorage";
import { DistributionProvider } from "./Distribution";
import { PolotnoProvider } from "./Polotno";
import { PortalProvider } from "./Portal";
import { SiteProvider } from "./Site";

type AppProviderProps = {
  children: React.ReactNode;
};

const customPtBRLocaleText: Partial<PickersLocaleText<any>> = {
  okButtonLabel: "Ok",
  cancelButtonLabel: "Cancelar",
  dateTimePickerToolbarTitle: "",
  datePickerToolbarTitle: "",
};

export function AppProvider({ children }: AppProviderProps) {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.init({ streaming: true });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GrowthBookProvider growthbook={growthbook}>
        <AuthProvider>
          <PolotnoProvider>
            <AzureStorageProvider>
              <AwsProvider>
                <ThemeProvider theme={configureDesignSystem}>
                  <DialogConfirmationProvider>
                    <ToastProvider>
                      <DateProvider>
                        {/* <NpsProvider> */}
                        <StudioProvider>
                          <SiteProvider>
                            <DistributionProvider>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={ptBR}
                                localeText={customPtBRLocaleText}
                              >
                                <PortalProvider>{children}</PortalProvider>
                              </LocalizationProvider>
                            </DistributionProvider>
                          </SiteProvider>
                        </StudioProvider>
                        {/* </NpsProvider> */}
                      </DateProvider>
                    </ToastProvider>
                  </DialogConfirmationProvider>
                </ThemeProvider>
              </AwsProvider>
            </AzureStorageProvider>
          </PolotnoProvider>
        </AuthProvider>
      </GrowthBookProvider>
    </QueryClientProvider>
  );
}
