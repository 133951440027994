import React, { createContext, useEffect, useMemo, useState } from "react";
import { createStore, StoreType } from "polotno/model/store";
import { FONT } from "polotno/utils/fonts";
import {
  setTranslations,
  unstable_setRemoveBackgroundEnabled,
  unstable_setTextOverflow,
  // unstable_setAnimationsEnabled,
} from "polotno/config";
import translations from "../locale/polotno.json";
import { hexToRgb } from "../utils/adjustPreviewArt";
import { makeId } from "../utils/stringIdGenerate";
// import { isLocalhost } from "../utils/stage";
import adjustLayout, {
  adjustLayoutDescription,
  adjustLayoutFooter,
  adjustLayoutTags,
  adjustLayoutTagsCustom,
  adjustLayoutProducts,
  adjustLayoutTagsMinors,
  adjustLayoutTagsDiscount,
  adjustSizeProductImage,
  adjustSizeProductDescription,
  adjustFontCase,
  adjustFontFamily,
  adjustViewUnits,
  pad,
} from "../utils/adjustLayout";
import typesArts from "../utils/typesArts";
import { Products } from "../types/studio";

setTranslations(translations);
unstable_setRemoveBackgroundEnabled(true);
// unstable_setAnimationsEnabled(!isLocalhost);
unstable_setTextOverflow("change-font-size");
unstable_setTextOverflow("resize");

const store = createStore({
  key: import.meta.env.VITE_REACT_APP_POLOTNO_KEY as string,
  showCredit: false,
});

type PolotnoProviderProps = {
  children: React.ReactNode;
};

type LayoutAttributes = {
  professional?: boolean;
  type?: string;
  tagURL?: string;
  backgroundURL?: string;
  colors?: any;
  elements?: any[];
  store: {
    endereco?: string;
    telefone?: string;
    descricao?: string;
    logo?: string;
  };
};

type TagsAttributes = {
  tagURL?: string;
  tagURLPrecoUnico?: string;
  tagURLDePor?: string;
  tagURLVarejAtac?: string;
  tagURLPackUnit?: string;
  tagURLNormalClub?: string;
};

export type PolotnoContextData = {
  element?: any;
  store: StoreType;
  handleSetElement: (data: any) => void;
  subscribeToChanges: (callback: (dadosDoEvento: any) => void) => () => void;
  handleChangeAuxiliarText: (key: string, value: string) => void;
  handleChangeFormat: (height: number, width: number, isEdit?: boolean) => void;
  handleChangeLayout: (
    templateURL: any,
    templateException?: any,
    productsPage?: any[],
    productsPerPage?: number,
    attributes?: LayoutAttributes,
    isEdit?: boolean,
    isFree?: boolean
  ) => void;
  handleChangeColor: (color: string, key: string, isEdit?: boolean) => void;
  handleChangeDescription: (
    key: string,
    value: string,
    custom?: object,
    isEdit?: boolean
  ) => void;
  handleChangeTagsCustom: (tag: any[], isEdit?: boolean) => void;
  handleChangeFooter: (
    footer: any[],
    description?: object,
    custom?: object,
    isEdit?: boolean
  ) => void;
  handleChangeTagsMinors: (show: boolean, product: Products) => void;
  handleChangeTagsDiscounts: (show: boolean, product: Products) => void;
  handleChangeTags: (
    tagURL?: string,
    tagType?: string,
    custom?: object,
    isEdit?: boolean,
    hasRightBtn?: boolean
  ) => void;
  handleChangeBackground: (
    background: string,
    custom?: object,
    isEdit?: boolean,
    isFree?: boolean
  ) => void;
  handleChangeProducts: (product: Products, tags?: TagsAttributes) => void;
  handleChangeSizeProductImage: (size: number, isEdit?: boolean) => void;
  handleChangeSizeProductDescription: (size: number, isEdit?: boolean) => void;
  handleChangeFontFamily: (
    font: string,
    type: string,
    isEdit?: boolean
  ) => void;
  handleChangeFontCase: (type: string, isEdit?: boolean) => void;
  handleChangeViewUnits: (type: string, isEdit?: boolean) => void;
};

export const PolotnoContext = createContext<PolotnoContextData>(
  {} as PolotnoContextData
);

export function PolotnoProvider({ children }: PolotnoProviderProps) {
  const [user] = useState(JSON.parse(localStorage.getItem("@datasales:user")!));
  const [element, setElement] = useState<any>();

  const handleChangeFormat = (
    height: number,
    width: number,
    isEdit?: boolean
  ) => {
    if (isEdit) {
      store.setSize(width, height);
    } else {
      const json = {
        unit: "px",
        width,
        height,
        dpi: 72,
        fonts: [],
        pages: [{ id: makeId(16), children: [] }],
      };

      store.loadJSON(json);
    }
  };

  const handleChangeBackground = (
    background: string,
    custom?: object,
    isEdit?: boolean,
    isFree?: boolean
  ) => {
    if (isEdit) {
      store.activePage.set({
        background,
        custom: { ...store.activePage.custom, ...custom },
      });
    } else {
      store.pages.forEach((page) => {
        page.set({
          background,
          custom: { ...page.custom, ...custom },
        });
      });
    }

    if (store.activePage && store.activePage.children) {
      const hasWaterMark = store.activePage.children
        .filter((item) => item?.custom?.name === "waterMark")
        .map((item) => {
          return item.id;
        });

      if (
        (user.signature === "free" || user.signature === "vencido") &&
        !isFree
      ) {
        if (hasWaterMark.length >= 1) return;
        const size = typesArts.filter(
          (item) => store.activePage.custom.formatId === item.formatId
        )[0];
        store.pages.map((page) =>
          page.addElement({
            type: "image",
            src: "https://datasalesio-imagens.s3.amazonaws.com/waterMark.png",
            height: size.height,
            width: size.width,
            custom: {
              name: "waterMark",
            },
            selectable: false,
            alwaysOnTop: true,
            showInExport: true,
          })
        );
      } else {
        store.deleteElements(hasWaterMark);
      }
    }
  };

  const handleChangeProducts = (product: Products, tags?: TagsAttributes) => {
    store.pages?.forEach((page) => {
      adjustLayoutProducts(page, product, tags);
    });
  };

  const handleChangeLayout = (
    template: any,
    templateException?: any,
    productsPage?: any[],
    productsPerPage?: number,
    attributes?: LayoutAttributes,
    isEdit?: boolean,
    isFree?: boolean
  ) => {
    const json = store.toJSON();
    const backgroundURL =
      attributes?.backgroundURL || template.pages[0].background;
    const templatePage = template.pages[0];

    if (isEdit) {
      let products: any[] = [];
      let pages = [...json.pages];
      const page = store.activePage.toJSON();
      const pageIndex = pages?.findIndex(
        (child: any) => child?.id === page?.id
      );

      let _productsPerPage =
        page.children?.filter((child: any) =>
          child?.custom?.name?.includes("produto-imagem")
        )?.length || 1;

      do {
        const _products = page?.children?.find((el: any) =>
          el?.custom?.name?.endsWith(`-${pad(_productsPerPage)}`)
        );
        products.unshift(_products?.custom?.values);

        _productsPerPage = _productsPerPage - 1;
      } while (_productsPerPage > 0);

      products = products.map((product) => ({
        medida: product.medida,
        id: product.id,
        imagem_produto: product.image_url || product.imagem_produto,
        nome_produto: product.name || product.nome_produto,
        preco_dois: product.club_price
          ? parseFloat(product.club_price?.replace(",", "."))
          : product.preco_dois || 0,
        preco_original: product.price
          ? parseFloat(product.price?.replace(",", "."))
          : product.preco_original || 0,
        produto_ativo: true,
        dinamica_comercial: product.dynamics || product.dinamica_comercial,
      }));

      let { children, elements, footer } = adjustLayout(
        page,
        page,
        templatePage,
        products,
        attributes
      );

      const newPage = {
        ...page,
        children: [...children, ...elements, ...footer],
      };

      pages[pageIndex] = newPage;

      store.loadJSON({ ...json, pages });
      // handleChangeTags(attributes?.tagURL, "preco_unico", {}, true, true);
    } else {
      if (
        json.pages?.length > 0 &&
        Number(productsPage?.flat()?.length) > 0 &&
        !attributes?.professional
      ) {
        productsPage = productsPage?.map((nPage, index: number) => {
          let { children, elements, footer } = adjustLayout(
            json.pages[index],
            json.pages[0],
            templatePage,
            nPage,
            attributes
          );

          if (
            nPage.length > 0 &&
            nPage.length !== productsPerPage &&
            templateException?.pages
          ) {
            const exception = adjustLayout(
              json.pages[index],
              json.pages[0],
              templateException?.pages[0],
              nPage,
              attributes
            );

            children = exception.children;
            elements = exception.elements;
            footer = exception.footer;
          }

          return {
            ...templatePage,
            background: backgroundURL,
            id: makeId(16),
            children: [...children, ...elements, ...footer],
          };
        });

        store.loadJSON({ ...json, pages: productsPage });
      } else {
        const children = template?.pages[0].children.map((child: any) => {
          const name = child?.custom?.name;
          if (name?.includes("produto-") && child?.type === "text") {
            return { ...child, text: " " };
          } else if (
            (name?.includes("produto-") && child?.type === "image") ||
            name?.includes("etiqueta-")
          ) {
            return {
              ...child,
              src: import.meta.env.VITE_REACT_IMAGE_TRANSPARENT,
            };
          } else {
            return child;
          }
        });

        store.loadJSON({
          ...template,
          pages: [
            { ...template?.pages[0], background: backgroundURL, children },
          ],
        });
      }

      attributes?.elements?.forEach((child: any) => {
        store.activePage.addElement({ ...child });
      });

      // handleChangeTags(attributes?.tagURL, "preco_unico");
    }

    if (attributes?.colors) {
      Object.entries(attributes.colors).forEach((color) => {
        if (color[1]) {
          const key =
            color[0] === "colorPrice" ? "produto-preco" : "produto-descricao";

          handleChangeColor(String(color[1]), key, isEdit);
        }
      });
    }

    if (attributes?.store) {
      Object.entries(attributes?.store).forEach((store) => {
        if (store[1]) {
          if (String(store[1]) === "logo" && !Boolean(String(store[0]))) {
            handleChangeDescription(
              String(store[0]),
              import.meta.env.VITE_REACT_IMAGE_TRANSPARENT,
              {},
              isEdit
            );
          }

          handleChangeDescription(
            String(store[0]),
            String(store[1]),
            attributes,
            isEdit
          );
        }
      });
    }

    const hasWaterMark =
      store.activePage.children.filter(
        (item) => item?.custom?.name === "waterMark"
      ).length === 0;

    if (
      (user.signature === "free" || user.signature === "vencido") &&
      hasWaterMark &&
      !isFree
    ) {
      const size = typesArts.filter(
        (item) => store.activePage.custom.formatId === item.formatId
      )[0];
      store.pages.map((page) =>
        page.addElement({
          type: "image",
          src: "https://datasalesio-imagens.s3.amazonaws.com/waterMark.png",
          height: size.height,
          width: size.width,
          custom: { name: "waterMark" },
          selectable: false,
          alwaysOnTop: true,
          showInExport: true,
        })
      );
    }
    template.fonts?.forEach((font: FONT) => store.addFont(font));
  };

  const handleChangeColor = (color: string, key: string, isEdit?: boolean) => {
    const hex = hexToRgb(color)!;

    if (isEdit) {
      store.activePage.children.forEach((child) => {
        if (child?.custom?.name?.includes(key)) {
          child.set({ fill: `rgba(${hex[0]}, ${hex[1]}, ${hex[2]}, 1)` });
        }
      });
    } else {
      store.pages.forEach((page) =>
        page.children.forEach((child) => {
          if (child?.custom?.name?.includes(key)) {
            child.set({ fill: `rgba(${hex[0]}, ${hex[1]}, ${hex[2]}, 1)` });
          }
        })
      );
    }
  };

  const handleChangeAuxiliarText = (key: string, value: string) => {
    const child = store.activePage.children?.find((child) =>
      child?.custom?.name?.includes(key)
    );

    if (child) {
      child?.set({ text: value });
    }
  };

  const handleChangeDescription = (
    key: string,
    value: string,
    custom?: object,
    isEdit?: boolean
  ) => {
    if (isEdit) {
      store.activePage.children?.forEach((child) =>
        adjustLayoutDescription(child, custom, key, value)
      );
    } else {
      store.pages.forEach((page) => {
        page.children.forEach((child) => {
          adjustLayoutDescription(child, custom, key, value);
        });
      });
    }
  };

  const handleChangeTags = (
    tagURL?: string,
    tagType?: string,
    custom?: object,
    isEdit?: boolean,
    hasRightBtn?: boolean
  ) => {
    if (isEdit) {
      if (hasRightBtn) {
        adjustLayoutTags(store.activePage, tagURL, tagType);
      } else {
        store.activePage.addElement({
          height: 300,
          width: 300,
          type: "image",
          src: tagURL,
          custom: { ...store.activePage.custom, ...custom },
        });
      }
    } else {
      store.pages.forEach((page) => {
        adjustLayoutTags(page, tagURL, tagType);
      });
    }
  };

  const handleChangeTagsMinors = (show: boolean, product: Products) => {
    store.pages?.forEach((page) => {
      adjustLayoutTagsMinors(store, page, show, product);
    });
  };

  const handleChangeTagsDiscounts = (show: boolean, product: Products) => {
    store.pages?.forEach((page) => {
      adjustLayoutTagsDiscount(store, page, show, product);
    });
  };

  const handleChangeTagsCustom = (elements: any[], isEdit?: boolean) => {
    if (isEdit) {
      adjustLayoutTagsCustom(store, store.activePage, elements);
    } else {
      store?.pages?.forEach((page) => {
        adjustLayoutTagsCustom(store, page, elements);
      });
    }
  };

  const handleChangeFooter = (
    elements: any[],
    description: any,
    custom: any,
    isEdit?: boolean
  ) => {
    if (isEdit) {
      adjustLayoutFooter(
        store,
        store.activePage,
        elements,
        description,
        custom
      );
    } else {
      store?.pages.forEach((page) => {
        adjustLayoutFooter(store, page, elements, description, custom);
      });
    }
  };

  const handleChangeSizeProductImage = (size: number, isEdit?: boolean) => {
    if (isEdit) {
      adjustSizeProductImage(store.activePage, size);
    } else {
      store.pages.forEach((page) => adjustSizeProductImage(page, size));
    }
  };

  const handleChangeSizeProductDescription = (
    size: number,
    isEdit?: boolean
  ) => {
    if (isEdit) {
      adjustSizeProductDescription(store.activePage, size);
    } else {
      store.pages.forEach((page) => adjustSizeProductDescription(page, size));
    }
  };

  const handleChangeFontCase = (type: string, isEdit?: boolean) => {
    if (isEdit) {
      adjustFontCase(store.activePage, type);
    } else {
      store.pages.forEach((page) => adjustFontCase(page, type));
    }
  };

  const handleChangeFontFamily = (
    font: string,
    type: string,
    isEdit?: boolean
  ) => {
    if (isEdit) {
      adjustFontFamily(store.activePage, font, type);
    } else {
      store.pages?.map((page) => adjustFontFamily(page, font, type));
    }
  };

  const handleChangeViewUnits = (type: string, isEdit?: boolean) => {
    if (isEdit) {
      adjustViewUnits(store.activePage, type);
    } else {
      store.pages?.map((page) => adjustViewUnits(page, type));
    }
  };

  const subscribers: Array<(dadosDoEvento: any) => void> = [];
  let debounceTimer: NodeJS.Timeout | null = null;

  const notifyChange = (dadosDoEvento?: any) => {
    // Limpa o timer se houver um agendado
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Configura um novo timer para chamar a lógica da API após 500ms (ajuste conforme necessário)
    debounceTimer = setTimeout(() => {
      subscribers.forEach((subscriber) => {
        subscriber(dadosDoEvento);
      });
    }, 500); // Tempo de debounce em milissegundos
  };

  const subscribeToChanges = (callback: (dadosDoEvento: any) => void) => {
    subscribers.push(callback);

    // Retorna uma função de limpeza para remover o ouvinte quando o componente é desmontado
    return () => {
      const index = subscribers.indexOf(callback);
      if (index !== -1) {
        subscribers.splice(index, 1);
      }
    };
  };

  useEffect(() => {
    const unsubscribe = store.on("change", notifyChange);

    return () => {
      unsubscribe();
      // Limpa o timer quando o componente é desmontado
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [store, subscribers]);

  const handleSetElement = (data: any) => {
    setElement(data);
  };
  const PolotnoContextData = useMemo(() => {
    return {
      handleChangeProducts,
      handleChangeFormat,
      handleChangeLayout,
      handleChangeColor,
      handleChangeDescription,
      handleChangeFooter,
      handleChangeTags,
      handleChangeTagsCustom,
      handleChangeTagsMinors,
      handleChangeTagsDiscounts,
      handleChangeBackground,
      handleChangeAuxiliarText,
      handleChangeSizeProductImage,
      handleChangeSizeProductDescription,
      handleChangeFontFamily,
      handleChangeViewUnits,
      handleChangeFontCase,
      subscribeToChanges,
      handleSetElement,
      element,
      store,
    };
  }, [element]);

  return (
    <PolotnoContext.Provider value={PolotnoContextData}>
      {children}
    </PolotnoContext.Provider>
  );
}
