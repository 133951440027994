export const adjustImage = (formatId?: number) => {
  switch (formatId) {
    case 1:
      return "70ch";
    case 2:
    case 14:
    case 16:
      return "55ch";
    case 5:
      return "75ch";
    case 3:
      return "40ch";
    case 15:
      return "75ch";
    case 35:
      return "75ch";
    default:
      return "70ch";
  }
};

export const hexToRgb = (hex: string) => {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$i/,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));
};

export const parseColor = (color: string) => {
  let r, g, b, a;

  if (color.startsWith("rgb")) {
    // Expressão regular para RGB e RGBA
    const rgbaPattern =
      /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*([\d\.]+)\s*)?\)$/;
    const result = rgbaPattern.exec(color);

    if (!result) {
      return {};
    }

    // Extrair valores de r, g, b, a
    r = parseInt(result[1], 10);
    g = parseInt(result[2], 10);
    b = parseInt(result[3], 10);
    a = result[4] !== undefined ? parseFloat(result[4]) : 1;
  } else if (color.startsWith("#")) {
    // Remover o símbolo #
    const hex = color.slice(1);

    if (hex.length === 3) {
      // Formato hexadecimal curto (e.g., #fff)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Formato hexadecimal completo (e.g., #ffffff)
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    } else {
      return {};
    }

    a = 1; // O hexadecimal não possui transparência
  } else {
    return {};
  }

  // Converter para hexadecimal
  const hex = `#${((1 << 24) | (r << 16) | (g << 8) | b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

  // Criar o objeto RGB
  const rgbObject = { r, g, b };

  return { hex, rgbObject };
};

export const findFormatName = (formatId: number) => {
  switch (formatId) {
    case 0:
      return "Vídeos";
    case 1:
      return "Post";
    case 2:
      return "Encarte";
    case 3:
      return "Story";
    case 5:
      return "TV";
    case 14:
      return "Cartaz retrato";
    case 15:
      return "Cartaz paisagem";
    case 16:
      return "Etiquetas";
    case 18:
      return "Personalizado";
    case 36:
      return "PDF";
    case 98:
      return "Importados";
    case 99:
      return "Canva";
    default:
      return "Post";
  }
};
